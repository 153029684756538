import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, Modal } from '@bp/ui-components';

import { InstantMeetingsForm } from './InstantMeetingModal/InstantMeetingsForm';
import { AvailableService } from '../../hooks/useAvailableServicesForOrganization';
import { useRunningPlannedMeetings } from '../../hooks/useCalendarEvents';
import { useAuthClaims } from '../../hooks/useAuthClaims';

export function InstantMeetingModal({
  showModal,
  setInstantMeetingModalOpen,
  availableServices,
  groupUuid,
}: {
  showModal: boolean;
  setInstantMeetingModalOpen: (state: boolean) => void;
  availableServices: AvailableService[];
  groupUuid: string;
}) {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const { refetchEvents } = useRunningPlannedMeetings(pimAuthClaims.getProfile().uuid);

  const handleClose = () => {
    setInstantMeetingModalOpen(false);
    refetchEvents();
  };

  return (
    <Modal
      style={{
        content: {
          maxWidth: ' 500px',
        },
      }}
      footer={
        <div>
          <Button onClick={handleClose} hierarchy='secondary' icon={<CloseIcon />}>
            {t('common.cancel')}
          </Button>
        </div>
      }
      onRequestClose={handleClose}
      shouldCloseOnEsc
      isOpen={showModal}
      title={t('meetings.instantMeeting')}
    >
      <InstantMeetingsForm handleClose={handleClose} groupUuid={groupUuid} availableServices={availableServices} />
    </Modal>
  );
}
