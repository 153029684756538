import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { ClassesList } from 'components/ClassesList/ClassesList';

export type BpDivisionType = {
  studentCount: number;
  name: string;
};

export type BpClassesType = {
  uuid?: string;
  name: string;
  shortName: string;
  grade: number | null | undefined;
  gradeGroupUuid?: string | null;
  tutor_1?: string;
  tutor_2?: string;
  tutor_3?: string;
  color?: string;
  colorLabel?: string;
  studentCount?: number;
  source?: string;
  divisions?: BpDivisionType[][];
};

export const InstitutionClassesSubpage = () => {
  return (
    <BpSubpage>
      <ClassesList />
    </BpSubpage>
  );
};
