import { Grid, GridColumn, GridRow, Input, ModalBottomButtons, Select } from '@bp/ui-components';
import { Form, Formik } from 'formik';
import { BpClassesType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ClassesFormProps = {
  selectedClass?: BpClassesType;
  onClose: () => void;
};

export const ClassesForm = ({ selectedClass, onClose }: ClassesFormProps) => {
  const { t } = useTranslation();

  const [loading] = useState<boolean>(false);

  const initialValues: BpClassesType = {
    uuid: selectedClass?.uuid ?? '',
    name: selectedClass?.name ?? '',
    shortName: selectedClass?.shortName ?? '',
    grade: selectedClass?.grade ?? null,
    gradeGroupUuid: selectedClass?.gradeGroupUuid ?? null,
    tutor_1: selectedClass?.tutor_1 ?? '',
    tutor_2: selectedClass?.tutor_2 ?? '',
    tutor_3: selectedClass?.tutor_3 ?? '',
    color: selectedClass?.color ?? '',
    colorLabel: selectedClass?.colorLabel ?? '',
    studentCount: selectedClass?.studentCount ?? 0,
    source: selectedClass?.source ?? '',
    divisions: selectedClass?.divisions ?? [],
  };

  return (
    <Formik<BpClassesType>
      onSubmit={() => console.log('submit')}
      initialValues={initialValues}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {({ errors, values }) => {
        return (
          <Form>
            <Grid>
              <GridRow>
                <GridColumn width={3}>
                  <Input
                    name='grade'
                    value={values.grade}
                    label={t('classes.grade')}
                    onChange={() => console.log('TODO: implement')}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Input
                    name='gradeGroup'
                    value={values.gradeGroupUuid}
                    label={t('classes.gradeGroup')}
                    onChange={() => console.log('TODO: implement')}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Input
                    name='name'
                    value={values.name}
                    label={t('common.designation')}
                    onChange={() => console.log('TODO: implement')}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Input
                    name='shortNmae'
                    value={values.shortName}
                    label={t('classes.shortName')}
                    onChange={() => console.log('TODO: implement')}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={3}>
                  <Select
                    options={[]}
                    onChange={() => console.log('TODO: implement')}
                    name='tutor_1'
                    label={t('classes.tutor') + ' 1'}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Select
                    options={[]}
                    onChange={() => console.log('TODO: implement')}
                    name='tutor_2'
                    label={t('classes.tutor') + ' 2'}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Select
                    options={[]}
                    onChange={() => console.log('TODO: implement')}
                    name='tutor_3'
                    label={t('classes.tutor') + ' 3'}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Select
                    options={[]}
                    onChange={() => console.log('TODO: implement')}
                    name='color'
                    label={t('common.color')}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              isLoading={loading}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
