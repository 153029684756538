import { useEffect, useState } from 'react';
import styles from './MatrixChat.module.scss';
import { ArrowLeftIcon, Button, ErrorState, Input, SearchIcon } from '@bp/ui-components';
import { MatrixRoomList } from './MatrixRoomList/MatrixRoomList';
import { MatrixConversation } from './MatrixConversation/MatrixConversation';
import { useTranslation } from 'react-i18next';
import { MsgType } from 'matrix-js-sdk';
import { useMatrixClient } from '../../hooks/matrix/useMatrixClient';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { useMatrixDmRooms } from '../../hooks/matrix/useMatrixDmRooms';
import { useMatrixNameResolver } from '../../hooks/matrix/useMatrixNameResolver';
import { BpCard } from '../BpCard/BpCard';
import { useRefreshOnEvent } from '../../hooks/matrix/useRefreshOnEvent';
import { RoomMessageEventContent } from 'matrix-js-sdk/lib/types';
import { useWindowDimensions } from 'utils/dimensions';
import classNames from 'classnames';

type MatrixChatProps = {
  matrixRoomId?: string;
};

export const MatrixChat = ({ matrixRoomId }: MatrixChatProps) => {
  const { t } = useTranslation();
  const matrixAvailable = useMatrixAvailable();
  const matrixClient = useMatrixClient();
  const [search, setSearch] = useState<string>('');
  const [roomId, setRoomId] = useState<string | null>(null);
  const { resolveRoomName } = useMatrixNameResolver();
  const rooms = useMatrixDmRooms();

  const currentRoom = roomId ? rooms?.find((room) => room.roomId === roomId) : null;
  const roomInformation = currentRoom ? resolveRoomName(currentRoom?.name) : '';

  const { width } = useWindowDimensions();
  const isSmall = width < 770;

  useEffect(() => {
    if (matrixRoomId) {
      setRoomId(matrixRoomId);
    }
  }, [matrixRoomId]);

  useRefreshOnEvent();

  const handleSend = async (roomId: string, input: string) => {
    if (roomId) {
      const content: RoomMessageEventContent = {
        body: input,
        msgtype: MsgType.Text,
      };
      await matrixClient?.sendMessage(roomId, content);
    }
  };

  return (
    <div className={styles.messenger}>
      {!matrixAvailable ? (
        <BpCard fitParent noPadding>
          <ErrorState title={t('matrix.noAvailable')} />
        </BpCard>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles['side-bar']}>
            <div className={styles.search}>
              <Input
                className={styles.input}
                onChange={(event) => setSearch(event.target.value)}
                value={search}
                name='search'
                prefix={<SearchIcon />}
                dense
                placeholder={t('messages.searchPerson')}
              />
            </div>
            <div className={styles['rooms-list']}>
              <MatrixRoomList onSelect={(roomId) => setRoomId(roomId)} selected={roomId} searchValue={search} />
            </div>
          </div>
          <div className={classNames(styles.chat, { [styles['fly-out']]: isSmall && currentRoom && roomInformation })}>
            {currentRoom && roomInformation && (
              <div className={styles.room}>
                {isSmall && <Button icon={<ArrowLeftIcon />} hierarchy='ghost' onClick={() => setRoomId(null)} />}
                {t('messages.chatWith', { name: roomInformation.displayName })}
              </div>
            )}
            <div className={styles.conversation}>
              <MatrixConversation selectedRoom={currentRoom?.roomId} onSend={handleSend} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
