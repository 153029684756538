import { Grid, GridColumn, GridRow, ImportIcon, Modal } from '@bp/ui-components';
import { BpCard } from '../../../components/BpCard/BpCard';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { PersonsList } from '../../../components/PersonsList/PersonsList';
import { useRedirectToDmMessagePage } from '../../../hooks/matrix/useRedirectToDmMessagePage';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import {
  useBpCollaboratingOrganizationsQuery,
  useBpUpdateGroupsMutation,
  useGroupAsCourseQuery,
} from '../../../client/bp-graphql-client-defs';
import { extractProfilesByRoles } from '../../../utils/extractProfilesByRoles';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { splitProfilesByOrganization } from '../../../utils/splitProfilesByOrganization';
import styles from './CourseSubpages.module.scss';
import { GroupType } from '@bp/bp-graphql-types';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { CreateUserForm } from '../../../components/CreateUserForm/CreateUserForm';

export const CoursePersonsSubpage: FC = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { courseUuid } = useParams<{ courseUuid: string }>();
  const { pimAuthClaims } = useAuthClaims();

  const [roleLoading, setRoleLoading] = useState<string | null>(null);
  const { redirectToDmMessagePage, loading } = useRedirectToDmMessagePage('course');
  const [, bpUpdateGroup] = useBpUpdateGroupsMutation();
  const [userModal, setUserModal] = useState<{ showModal: boolean; userUuid: string | null }>({
    showModal: false,
    userUuid: null,
  });

  const context = useMemoizedCacheTag('COURSE');
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [{ data }] = useGroupAsCourseQuery({
    variables: { where: { uuid: courseUuid ?? '', type: GroupType.Course } },
    context,
  });

  const admins = data?.groups[0].admins ?? [];
  const editors = data?.groups[0].editors ?? [];
  const viewers = data?.groups[0].viewers ?? [];
  const splitEditors = splitProfilesByOrganization(
    editors.map((a) => {
      return { ...a, displayName: a.displayName ?? '' };
    }),
  );
  const schoolUuidsFromEditors = splitEditors ? Object.keys(splitEditors) : [];
  const splitViewers = splitProfilesByOrganization(
    viewers.map((a) => {
      return { ...a, displayName: a.displayName ?? '' };
    }),
  );

  const schoolUuidsFromViewers = splitViewers ? Object.keys(splitViewers) : [];
  const uniqueOrganizationUuids = [
    ...new Set([pimAuthClaims.getOrganizationUuid(), ...schoolUuidsFromViewers, ...schoolUuidsFromEditors]),
  ];

  const collaborationContext = useMemoizedCacheTag('COLLABORATING');
  const [{ data: collaboratingData }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
    context: collaborationContext,
  });

  const organizations = collaboratingData?.collaboratingOrganizations;

  const toggleRole = async (uuid: string, isEditor: boolean) => {
    setRoleLoading(uuid);
    const newEditors = isEditor
      ? editors.filter((e) => e.uuid !== uuid).map((e) => e.uuid)
      : [...editors.map((e) => e.uuid), uuid];
    const newViewers = isEditor
      ? [...viewers.map((v) => v.uuid), uuid]
      : viewers.filter((v) => v.uuid !== uuid).map((e) => e.uuid);

    if (courseUuid && newEditors) {
      await bpUpdateGroup(
        {
          uuid: courseUuid,
          update: {
            editors: newEditors,
            viewers: newViewers,
            admins: admins.map((a) => a.uuid),
          },
        },
        context,
      );
    }
    setRoleLoading(null);
  };

  return (
    <BpSubpage className={styles['course-persons-subpage']}>
      {uniqueOrganizationUuids.map((uuid) => {
        const { TEACHER: teachers, STUDENT: students } = extractProfilesByRoles({
          profiles: [
            ...(splitEditors && splitEditors[uuid] ? splitEditors[uuid] : []),
            ...(splitViewers && splitViewers[uuid] ? splitViewers[uuid] : []),
          ],
          profileRoles: [ProfileRoles.Teacher, ProfileRoles.Student, ProfileRoles.Parent, ProfileRoles.Other],
        });

        const cardData = [
          {
            title: t('students.title'),
            persons: students,
            subHeadline: students.length.toString(),
            actions: perms?.canDownloadClasslist({
              uuid: courseUuid ?? '',
              organization: { uuid: pimAuthClaims.getOrganizationUuid() },
            })
              ? [
                  {
                    text: t('courses.classlist'),
                    icon: <ImportIcon className='svg-icon small' />,
                    callback: () => console.log('download clicked'),
                  },
                ]
              : undefined,
          },
          {
            title: t('teachers.title'),
            persons: teachers,
            subHeadline: teachers.length.toString(),
          },
        ];

        return (
          <div className={styles.organization} key={uuid}>
            {uniqueOrganizationUuids.length > 1 && (
              <div className={styles.header}>
                {uniqueOrganizationUuids.length > 1 && (
                  <div className={styles.title}>
                    {uuid === organizationUuid
                      ? t('common.myOrganization')
                      : organizations?.find((o) => o.uuid === uuid)?.name}
                  </div>
                )}
              </div>
            )}

            <Grid useFormGap>
              <GridRow mobileGap='var(--grid-form-gap)'>
                {cardData.map((card, index) => (
                  <GridColumn key={index} width={6}>
                    <BpCard
                      noPadding
                      header={{
                        headline: card.title,
                        subHeadline: card.subHeadline,
                      }}
                    >
                      <PersonsList
                        maxHeight={uniqueOrganizationUuids.length > 1 ? 600 : undefined}
                        organizationUuid={organizationUuid}
                        persons={card.persons.map((person) => ({
                          uuid: person.uuid,
                          organization: person.organization.uuid,
                          displayName: person.displayName ?? '',
                          userStatus: person.userStatus,
                          isEditor: editors.some((editor) => editor.uuid === person.uuid),
                          email: person.email ?? '',
                          profileRole: person.organizationRoles.filter(
                            (r) =>
                              r === ProfileRoles.Teacher ||
                              r === ProfileRoles.Student ||
                              r === ProfileRoles.Parent ||
                              r === ProfileRoles.Other,
                          )[0] as unknown as ProfileRoles,
                          canMessage: perms?.canDirectMessageWithinCourse(
                            { uuid: courseUuid ?? '', organization: { uuid: organizationUuid } },
                            {
                              uuid: person.uuid,
                              organization: { uuid: organizationUuid },
                              role: person.organizationRoles.filter(
                                (r) =>
                                  r === ProfileRoles.Teacher ||
                                  r === ProfileRoles.Student ||
                                  r === ProfileRoles.Parent ||
                                  r === ProfileRoles.Other,
                              )[0] as unknown as ProfileRoles,
                            },
                          ),
                        }))}
                        isEditable={perms?.canChangeBpGroupPermissions({
                          uuid: courseUuid ?? '',
                          organization: { uuid: organizationUuid },
                        })}
                        onChat={async (person) => {
                          if (courseUuid && person.uuid) {
                            await redirectToDmMessagePage({
                              groupUuid: courseUuid,
                              targetProfileUuid: person.uuid,
                              targetProfileOrganizationUuid: '',
                              targetProfileRole: person.profileRole,
                            });
                          }
                        }}
                        {...(perms?.isOmniAdmin()
                          ? { onToggle: (uuid, isEditor) => uuid && toggleRole(uuid, isEditor) }
                          : {})}
                        {...(perms?.canCreateUserInCourse({
                          uuid: courseUuid ?? '',
                          organization: { uuid: organizationUuid },
                        })
                          ? {
                              onProfile: (uuid) => {
                                setUserModal({ showModal: true, userUuid: uuid });
                              },
                            }
                          : {})}
                        loading={loading || roleLoading}
                      />
                    </BpCard>
                  </GridColumn>
                ))}
              </GridRow>
            </Grid>
          </div>
        );
      })}

      {userModal.userUuid && (
        <Modal
          title={t('personsList.createUser')}
          onRequestClose={() => setUserModal({ showModal: false, userUuid: null })}
          isOpen={userModal.showModal}
        >
          <CreateUserForm
            uuid={userModal.userUuid}
            setModalClosed={() => setUserModal({ showModal: false, userUuid: null })}
          />
        </Modal>
      )}
    </BpSubpage>
  );
};
