import { Modal } from '@bp/ui-components';
import { BpClassesType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import { ClassesForm } from '../forms/ClassesForm';

export type ClassesListModalProps = {
  selectedClass?: BpClassesType;
  isOpen: boolean;
  onClose: () => void;
};

export const ClassesListModal = ({ selectedClass, isOpen, onClose }: ClassesListModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={selectedClass ? t('classes.edit') : t('classes.add')} isOpen={isOpen} onRequestClose={onClose}>
      <ClassesForm selectedClass={selectedClass} onClose={onClose} />
    </Modal>
  );
};
