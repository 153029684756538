import { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  GroupType,
  useBpCreateGroupsMutation,
  useBpUpdateGroupsMutation,
  useGroupAsGroupsQuery,
} from '../../client/bp-graphql-client-defs';
import { ArrowLeftIcon, ArrowRightIcon } from '@bp/ui-components';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import styles from './GroupForm.module.scss';
import { groupFormSchema } from './validation/groupFormSchema';
import { GroupFormProfiles } from './GroupFormProfiles';
import { GroupFormBaseData } from './GroupFormBaseData';
import { GroupFormAdmins } from './GroupFormAdmins';
import { splitProfilesByOrganization } from '../../utils/splitProfilesByOrganization';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { showErrorToast } from '../../utils/showErrorToast';
import classNames from 'classnames';

export type CollaborationAdminsValues = {
  [schoolUuid: string]: { uuid: string; displayName: string }[];
};

export type GroupFormValues = {
  name: string;
  schoolyear: string | null;
  description?: string;
  admins: string[];
  editors: string[];
  viewers: string[];
  collaborationSchools: string[];
  collaborationAdmins: CollaborationAdminsValues;
  collaborating: boolean;
};

interface GroupsFormProps {
  groupUuid: string;
  onClose: () => void;
  canCollaborate?: boolean;
}

export const GroupForm = ({ groupUuid, onClose, canCollaborate = false }: GroupsFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [step, setStep] = useState<number>(0);
  const [maxSteps, setMaxSteps] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const context = useMemoizedCacheTag('GROUP');
  const [{ data }] = useGroupAsGroupsQuery({
    variables: { where: { uuid: groupUuid } },
    context,
  });

  const currentGroup = data?.groups[0];
  const [, bpCreateGroup] = useBpCreateGroupsMutation();
  const [, bpUpdateGroup] = useBpUpdateGroupsMutation();

  const handleSubmit = async (values: GroupFormValues, formHelpers: FormikHelpers<GroupFormValues>) => {
    if (loading) {
      return;
    }
    setLoading(true);

    const { name, schoolyear, admins, editors, viewers, description, collaborationAdmins } = values;

    if (!canCollaborate && !editors.includes(pimAuthClaims.getProfile().uuid)) {
      editors.push(pimAuthClaims.getProfile().uuid);
    }

    if (!admins.includes(pimAuthClaims.getProfile().uuid)) {
      admins.push(pimAuthClaims.getProfile().uuid);
    }

    const collabAdmins = Object.values(collaborationAdmins)
      .flatMap((v) => v?.map((item) => item.uuid))
      .filter((item) => item);

    const updateGroup = async () => {
      const { error } = await bpUpdateGroup(
        {
          update: {
            name,
            description,
            viewers: viewers,
            admins: [...admins, ...collabAdmins],
            editors: editors,
            schoolYear: schoolyear ?? '',
          },
          uuid: groupUuid,
        },
        context,
      );

      if (!error) {
        formHelpers.resetForm();
        onClose();
      } else {
        showErrorToast(error);
      }
    };

    const createGroup = async () => {
      const { error } = await bpCreateGroup(
        {
          input: {
            name,
            description,
            organization: organizationUuid,
            groupType: GroupType.Group,
            admins: [...admins, ...collabAdmins],
            editors: editors,
            viewers: viewers,
            schoolYear: schoolyear ?? '',
          },
        },
        context,
      );

      if (!error) {
        formHelpers.resetForm();
        onClose();
      } else {
        showErrorToast(error);
      }
    };

    groupUuid !== '' ? await updateGroup() : await createGroup();
    setLoading(false);
  };

  const otherOrganizations = currentGroup?.admins
    ? splitProfilesByOrganization(
        currentGroup.admins.map((a) => ({
          uuid: a.uuid,
          organization: a.organization,
          displayName: a.displayName ?? '',
        })),
        organizationUuid,
      )
    : null;

  const localAdmins =
    currentGroup?.admins.filter((admin) => admin.organization.uuid === organizationUuid).map((admin) => admin.uuid) ??
    [];

  const initialValue: GroupFormValues = {
    name: currentGroup?.name ?? '',
    description: currentGroup?.description ?? '',
    schoolyear: currentGroup?.schoolYear?.uuid ?? '',
    admins: localAdmins,
    editors: currentGroup
      ? currentGroup.editors.map((value) => value.uuid)
      : !canCollaborate
        ? [pimAuthClaims.getProfile().uuid]
        : [],
    viewers: currentGroup ? currentGroup.viewers.map((value) => value.uuid) : [],
    collaborationAdmins: otherOrganizations ?? {},
    collaborationSchools: otherOrganizations ? Object.keys(otherOrganizations) : [],
    collaborating: !!otherOrganizations,
  };

  function handleCollaborationChange(isCollaboration: boolean) {
    setMaxSteps(isCollaboration ? 2 : 1);
  }

  return (
    <Formik<GroupFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValue}
      validateOnBlur={true}
      enableReinitialize={true}
      validationSchema={groupFormSchema}
    >
      {({ errors, values }) => {
        return (
          <Form className={styles['group-form']}>
            <div className={styles.slider}>
              <div
                tabIndex={0}
                className={styles.slide}
                style={{ left: step === 0 ? '0' : step > 0 ? `${step * -100}%` : `${step * 100}%` }}
              >
                <GroupFormBaseData
                  readonly={!!currentGroup && !canCollaborate}
                  canCollaborate={!!canCollaborate}
                  onCollaborationChange={handleCollaborationChange}
                />
              </div>

              <div
                tabIndex={0}
                className={classNames(styles.slide, styles.profiles)}
                style={{ left: step === 1 ? '0' : step > 1 ? `${(step + 1) * -100}%` : `${(step + 1) * 100}%` }}
              >
                <GroupFormProfiles<GroupFormValues> />
              </div>

              {values.collaborating && (
                <div
                  tabIndex={0}
                  className={styles.slide}
                  style={{
                    left: step === 2 ? '0' : step > 2 ? `${(step + 2) * -100}%` : `${(step + 2) * 100}%`,
                  }}
                >
                  <GroupFormAdmins<GroupFormValues> />
                </div>
              )}
            </div>

            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              submitButton={{
                disabled: Object.keys(errors).length > 0 || step !== maxSteps,
              }}
              additionalButtons={[
                {
                  text: t('common.back'),
                  icon: <ArrowLeftIcon className={'small'} />,
                  disabled: step === 0,
                  callback: () => {
                    setStep((prev) => prev - 1);
                  },
                },
                {
                  text: t('common.next'),
                  icon: <ArrowRightIcon className={'small'} />,
                  iconPosition: 'right',
                  disabled: step === maxSteps || Object.keys(errors).length > 0,
                  callback: () => setStep((prev) => prev + 1),
                },
              ]}
              isLoading={loading}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
