import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { LazyLoader } from '@bp/ui-components';
import { Outlet } from 'react-router-dom';
import { BpPage } from '../../components/BpPage/BpPage';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export function SettingsPage() {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  const tabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: '/settings',
      pathMatchEnd: true,
    },
    ...(perms?.isOrganizationAdmin(organization)
      ? [
          {
            title: t('absenceReason.title'),
            path: 'absence-reasons',
          },
        ]
      : []),
    ...(perms?.canAcceptCollaboration(organization) || perms?.canCreateCollaboration(organization)
      ? [
          {
            title: t('collaborations.title'),
            path: 'collaborations',
          },
        ]
      : []),
    ...(perms?.canUpdateCollaborationGroups()
      ? [
          {
            title: t('collaborations.courses'),
            path: 'collaboration-courses',
          },
        ]
      : []),
    ...(perms?.canUpdateCollaborationGroups()
      ? [
          {
            title: t('collaborations.groups'),
            path: 'collaboration-groups',
          },
        ]
      : []),
    ...(perms?.isOmniAdmin()
      ? [
          {
            title: t('institution.procurat'),
            path: 'procurat',
          },
        ]
      : []),
  ];

  return (
    <BpPage title={t('settings.title')} isForbidden={!perms?.canViewSettings(organization)}>
      <QueryClientProvider client={new QueryClient()}>
        <NavigationTabs tabs={tabs} className='mb-6' />
        <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
      </QueryClientProvider>
    </BpPage>
  );
}
