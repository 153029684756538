import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MutationUpdateSubmissionsArgs,
  SortDirection,
  SubmissionStatus,
  useBpSubmissionsQuery,
  useBpUpdateSubmissionsMutation,
} from '../../client/bp-graphql-client-defs';
import styles from './Submission.module.scss';
import { BpActionButton, BpCard } from '../BpCard/BpCard';
import { ActivityProtocol } from '../ActivityProtocol/ActivityProtocol';
import { useTranslation } from 'react-i18next';
import {
  ArrowHeadDownIcon,
  ArrowHeadLeftIcon,
  Button,
  CheckIcon,
  EmptyState,
  ErrorState,
  Grid,
  GridColumn,
  GridRow,
  Input,
  InvisibleIcon,
  Modal,
  SchoolYearIllustration,
  ShareAlternateIcon,
  UpdateIcon,
} from '@bp/ui-components';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import { useActivityProtocol } from '../../hooks/matrix/useActivityProtocol';
import { Form, Formik } from 'formik';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { FileTable } from '../FileTable/FileTable';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { NotificationReason, NotificationType } from '../../utils/matrixClient';
import { BpHeader } from '../BpHeader/BpHeader';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { BpTipTapText } from 'components/BpTipTapText/BpTipTapText';
import { useWindowDimensions } from 'utils/dimensions';

type FormValues = {
  publishTitle: string;
  description: string;
};

export const Submission: FC = () => {
  const { courseUuid, submissionUuid, assignmentUuid } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();

  const { sendMessage, timelineListener } = useActivityProtocol();

  const { isPhone } = useWindowDimensions();

  const submissionContext = useMemoizedCacheTag('SUBMISSION');
  const [{ data: submissionData }, refreshSubmission] = useBpSubmissionsQuery({
    variables: {
      where: {
        uuid: submissionUuid,
      },
    },
    context: submissionContext,
  });

  const currentSubmission = useMemo(() => {
    return submissionData?.submissions[0];
  }, [submissionData]);

  const [{ data }, refreshOtherSubmissions] = useBpSubmissionsQuery({
    variables: {
      options: {
        sort: [
          {
            updated: SortDirection.Desc,
          },
        ],
      },
      where: {
        assignment_SINGLE: {
          uuid: assignmentUuid,
        },
        status: currentSubmission?.status,
      },
    },
    context: submissionContext,
  });

  timelineListener(submissionUuid ?? '', (event) => {
    if (event.getContent().msgtype === 'm.notice') {
      const messageMeta: Partial<NotificationType> = JSON.parse(event.getContent().body);
      if (
        messageMeta &&
        [NotificationReason.SubmissionNew, NotificationReason.RequestToFeedback].includes(
          messageMeta.type as NotificationReason,
        )
      ) {
        refreshSubmission({ requestPolicy: 'network-only' });
        refreshOtherSubmissions();
      }
    }
  });

  const submissionIndex = data?.submissions.findIndex((s) => s.uuid === submissionUuid) ?? 0;

  const [, updateSubmission] = useBpUpdateSubmissionsMutation();

  const handleUpdateSubmission = async (update: MutationUpdateSubmissionsArgs) => {
    const { error } = await updateSubmission(update);

    if (error) {
      showErrorToast(error);
      return false;
    } else {
      showSuccessToast(t('submissions.titleSingular'));
      return true;
    }
  };

  const gotoNext = (idx: number | undefined) => {
    if (typeof idx === 'number') {
      idx++;

      if (data?.submissions.length === idx) {
        // last Submission -> back to overview
        goBack();
      } else {
        const nextUuid = data?.submissions[idx]?.uuid;
        navigate(`/courses/${courseUuid}/assignments/${assignmentUuid}/submissions/${nextUuid}`);
      }
    }
  };

  const goBack = () => {
    navigate(`/courses/${courseUuid}/assignments/${assignmentUuid}`);
  };

  const doneAndGotoNext = async (idx: number | undefined) => {
    const update = {
      context: submissionContext,
      where: {
        uuid: submissionUuid,
      },
      update: {
        status: SubmissionStatus.Done,
      },
    };
    await handleUpdateSubmission(update);
    gotoNext(idx);
  };

  const reOpen = async () => {
    if (submissionUuid) {
      const update = {
        context: submissionContext,
        where: {
          uuid: submissionUuid,
        },
        update: {
          status: SubmissionStatus.Feedback,
        },
      };
      if (await handleUpdateSubmission(update)) {
        await sendMessage(submissionUuid, NotificationReason.SubmissionFeedback);
      }
    }
  };

  const requestToPublish = async (values: FormValues) => {
    toggleModal();
    if (submissionUuid) {
      await sendMessage(submissionUuid, NotificationReason.RequestToPublish, values.description);
    }
  };

  const material = data?.submissions[submissionIndex]?.material ?? {
    text: t('workmaterials.noWorkmaterials'),
    fileEntries: [],
  };

  const name = currentSubmission?.owner.displayName || t('common.unknown');

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const assignment = {
    uuid: assignmentUuid ?? '0',
    ownerUuid: currentSubmission?.owner.uuid ?? '0',
    groupUuid: currentSubmission?.assignment[0]?.holder.group.uuid ?? '0', // array.shift() klappt hier nicht!
    organization: { uuid: pimAuthClaims.getOrganizationUuid() },
  };

  if (
    !perms?.canViewSubmissions({
      uuid: currentSubmission?.uuid ?? '',
      ownerUuid: currentSubmission?.owner.uuid ?? '',
      groupUuid: currentSubmission?.assignment[0]?.holder.group.uuid ?? '',
      organization: pimAuthClaims.getOrganization(),
    })
  ) {
    return <ErrorState type={'forbidden'} onNavigateBack={goBack} />;
  }

  if (currentSubmission?.owner.uuid === pimAuthClaims.getProfile().uuid) {
    goBack();
    return;
  }

  if (!currentSubmission) {
    return <EmptyState icon={<InvisibleIcon />} title={t('submissions.noSubmissions')} fitParent forcedHeight='30vh' />;
  }

  const initialValues: FormValues = {
    publishTitle: '',
    description: '',
  };

  return (
    <div className={styles.submission}>
      {!perms?.canViewSubmissions(assignment) ? (
        <EmptyState
          icon={<InvisibleIcon />}
          iconColor='var(--color-error)'
          title={t('errors.noAccess')}
          subtitle={t('errors.noAccessHint')}
          fitParent
          forcedHeight='30vh'
        />
      ) : (
        <>
          {isPhone ? (
            <div className={styles['mobile-header']}>
              <div className={styles.status}>
                {currentSubmission?.status === SubmissionStatus.Done
                  ? `${t('submissions.teacher.done')}: ${name}`
                  : currentSubmission?.status === SubmissionStatus.Todo
                    ? `${t('submissions.teacher.todo')}: ${name}`
                    : currentSubmission?.status === SubmissionStatus.New
                      ? `${t('submissions.teacher.new')}: ${name}`
                      : ''}
              </div>
              <div className={styles.actions}>
                <div
                  className={styles.count}
                >{`${submissionIndex + 1} ${t('common.from')} ${data?.submissions.length}`}</div>
                <Button icon={<ArrowHeadLeftIcon />} onClick={goBack}>
                  {t('common.back')}
                </Button>
                {currentSubmission?.status === SubmissionStatus.New && (
                  <Button icon={<CheckIcon />} onClick={() => doneAndGotoNext(submissionIndex)}>
                    {t('submissions.finishAndNext')}
                  </Button>
                )}
                {data?.submissions.length !== submissionIndex + 1 && (
                  <Button icon={<ArrowHeadDownIcon />} onClick={() => gotoNext(submissionIndex)}>
                    {t('common.next')}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <BpHeader
              headline={
                currentSubmission?.status === SubmissionStatus.Done
                  ? `${t('submissions.teacher.done')}: ${name}`
                  : currentSubmission?.status === SubmissionStatus.Todo
                    ? `${t('submissions.teacher.todo')}: ${name}`
                    : currentSubmission?.status === SubmissionStatus.New
                      ? `${t('submissions.teacher.new')}: ${name}`
                      : ''
              }
              subHeadline={`${submissionIndex + 1} ${t('common.from')} ${data?.submissions.length}`}
              actions={[
                {
                  text: t('common.back'),
                  hierarchy: 'secondary',
                  icon: <ArrowHeadLeftIcon className='svg-icon ' />,
                  callback: goBack,
                } as BpActionButton,
                ...(currentSubmission?.status === SubmissionStatus.New
                  ? [
                      {
                        text: t('submissions.finishAndNext'),
                        hierarchy: 'secondary',
                        icon: <CheckIcon />,
                        callback: () => doneAndGotoNext(submissionIndex),
                      } as BpActionButton,
                    ]
                  : []),
                ...(data?.submissions.length !== submissionIndex + 1
                  ? [
                      {
                        text: t('common.next'),
                        hierarchy: 'secondary',
                        icon: <ArrowHeadDownIcon className='svg-icon ' />,
                        callback: () => gotoNext(submissionIndex),
                      } as BpActionButton,
                    ]
                  : []),
              ]}
            />
          )}

          <Grid>
            <GridRow mobileGap='var(--spacing-6)'>
              <GridColumn width={8}>
                <BpCard
                  noPadding
                  className={styles.answer}
                  header={{
                    headline: t('common.answer'),
                    actions: [
                      ...(!(
                        currentSubmission?.status === SubmissionStatus.Draft ||
                        currentSubmission?.status === SubmissionStatus.Feedback ||
                        currentSubmission?.status === SubmissionStatus.Todo
                      ) && !currentSubmission.public
                        ? [
                            {
                              text: t('submissions.requestToPublicise'),
                              icon: <ShareAlternateIcon />,
                              callback: toggleModal,
                            } as BpActionButton,
                          ]
                        : []),
                      ...(currentSubmission?.status !== SubmissionStatus.Todo &&
                      currentSubmission?.status !== SubmissionStatus.Draft &&
                      currentSubmission?.status !== SubmissionStatus.Feedback
                        ? [
                            {
                              text: t('submissions.teacher.feedback'),
                              callback: reOpen,
                              icon: <UpdateIcon />,
                            } as BpActionButton,
                          ]
                        : []),
                    ],
                  }}
                >
                  {currentSubmission?.status !== SubmissionStatus.Todo &&
                  currentSubmission?.status !== SubmissionStatus.Draft ? (
                    <div className={styles.data}>
                      <BpTipTapText content={material.text ?? ''} customPadding='var(--spacing-4) var(--spacing-6)' />
                      <FileTable
                        padding='var(--spacing-6)'
                        className={styles.list}
                        mode={'show'}
                        files={material.fileEntries ?? []}
                        breakpoint='580px'
                        emptyStateSettings={{
                          title: '',
                          size: 'small',
                          subtitle: '',
                          padding: 's',
                          hideIcon: true,
                        }}
                        isGroupEditor={perms?.isGroupEditor(currentSubmission?.assignment[0]?.holder.group) ?? false}
                      />
                    </div>
                  ) : (
                    <div className={styles['no-answer']}>
                      <SchoolYearIllustration className={styles.icon} />
                      <div className={styles.hint}>
                        {currentSubmission?.status === SubmissionStatus.Draft ? (
                          <div className={styles.text}>
                            {t('submissions.draft', { name: currentSubmission?.owner.displayName })}
                          </div>
                        ) : (
                          <div className={styles.text}>
                            {t('submissions.empty', { name: currentSubmission?.owner.displayName })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </BpCard>
              </GridColumn>
              <GridColumn width={4}>
                <BpCard
                  alwaysCollapsible
                  header={{
                    headline: t('activityProtocol.title'),
                  }}
                >
                  <ActivityProtocol submissionUuid={currentSubmission?.uuid ?? ''} newSubmissionContent={() => {}} />
                </BpCard>
              </GridColumn>
            </GridRow>
          </Grid>
        </>
      )}

      <Modal
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        title={t('workmaterials.requestPublication', { name: name })}
      >
        <Formik<FormValues> initialValues={initialValues} onSubmit={requestToPublish}>
          {({ setFieldValue, values, isSubmitting, errors }) => (
            <Form>
              <Input
                type={'text'}
                className='mb-2'
                label={t('common.description')}
                onChange={(e) => setFieldValue('description', e.target.value)}
                value={values.description}
                name='desc'
              />
              <div>{t('submissions.willBePublic', { name: name })}</div>
              <ModalBottomButtons closeButton={{ callback: toggleModal }} isLoading={isSubmitting} errors={errors} />
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
