import { useEffect, useRef, useState } from 'react';
import styles from './MatrixConversation.module.scss';
import { Button, EmptyState, RoomIcon, SendIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useGetMatrixRoom } from '../../../hooks/matrix/useGetMatrixRoom';
import { useMatrixClient } from '../../../hooks/matrix/useMatrixClient';
import { MatrixConversationItem } from './MatrixConversationItem/MatrixConversationItem';
import { MatrixEvent } from 'matrix-js-sdk';
import { useMatrixRoomMessagesAsConversation } from '../../../hooks/matrix/useMatrixRoomMessagesAsConversation';
import { Capacitor } from '@capacitor/core';

type ConversationProps = {
  selectedRoom?: string | null;
  conversation?: ConversationType;
  onSend: (roomId: string, input: string) => void;
};

export type ConversationType = {
  initials: string;
  isIncoming: boolean;
  color: string;
  id: string;
  messages: {
    type: string;
    content: string;
    date: Date;
    matrixEvent?: MatrixEvent;
  }[];
}[];

export const MatrixConversation = ({ selectedRoom, onSend }: ConversationProps) => {
  const { t } = useTranslation();
  const matrixClient = useMatrixClient();
  const conversation = useMatrixRoomMessagesAsConversation(selectedRoom ?? '');
  const room = useGetMatrixRoom(selectedRoom ?? '');

  const chatRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const [input, setInput] = useState<string>('');
  const [inputRows, setInputRows] = useState<number>(1);
  const [preventScroll, setPreventScroll] = useState<boolean>(false);
  const [roomLimit, setRoomLimit] = useState<number>(5);
  const [showLoadMore] = useState<boolean>(false);

  const isInputEmpty = (input: string) => !input || input === '' || !input.trim().length;

  function handleInputHeight() {
    const rows = Math.floor((inputRef.current?.scrollHeight ?? 18) / 18);
    setInputRows(rows >= 3 ? 3 : rows);
  }

  function handleSend(selectedRoom: string) {
    if (isInputEmpty(input)) return;
    onSend(selectedRoom, input);
    setPreventScroll(false);
    setInput('');
    setInputRows(1);
  }

  // smooth scroll after submit and for incoming messages
  useEffect(() => {
    if (chatRef.current && !preventScroll) {
      chatRef.current.scrollTo({ top: chatRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [preventScroll, chatRef, conversation, chatRef.current?.scrollHeight, input]);

  // jump to end on room selection
  useEffect(() => {
    if (chatRef.current && !preventScroll) {
      chatRef.current.scrollTo({ top: chatRef.current.scrollHeight, behavior: 'auto' });
    }
  }, [preventScroll, chatRef, selectedRoom, conversation]);

  useEffect(() => {
    setPreventScroll(false);
    setInput('');
  }, [selectedRoom, setPreventScroll]);

  return (
    <div className={styles.conversation}>
      {!selectedRoom ? (
        <EmptyState fitParent icon={<RoomIcon />} title={t('messages.chooseRoom')} />
      ) : (
        <>
          <div className={styles.chat} ref={chatRef}>
            {showLoadMore && (
              <div
                className={styles['load-more']}
                onClick={async () => {
                  if (room) {
                    setPreventScroll(true);
                    await matrixClient?.scrollback(room, roomLimit);
                    setRoomLimit(roomLimit + 5);
                  }
                }}
              >
                {t('messages.loadMore')}
              </div>
            )}
            {conversation.map((chunk) => {
              return <MatrixConversationItem chunk={chunk} key={chunk.id} />;
            })}
          </div>
          <div className={styles['input-area']}>
            <textarea
              ref={inputRef}
              name='input'
              className={classNames(styles.input, 'has-scrollbar')}
              rows={inputRows}
              placeholder={t('messages.typeMessage')}
              onInput={(e) => {
                if (isInputEmpty(e.currentTarget.value)) {
                  setInputRows(1);
                  setInput('');
                  return;
                }
                setInput(e.currentTarget.value);
                handleInputHeight();
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  !(Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') &&
                  !e.shiftKey
                ) {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSend(selectedRoom);
                } else if (e.key === 'Escape') {
                  inputRef.current?.blur();
                }
              }}
              value={input}
            />
            <Button className={styles['send-button']} icon={<SendIcon />} onClick={() => handleSend(selectedRoom)} />
          </div>
        </>
      )}
    </div>
  );
};
