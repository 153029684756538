import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { LazyLoader } from '@bp/ui-components';
import { Outlet } from 'react-router-dom';
import { BpPage } from '../../components/BpPage/BpPage';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';

export function InstitutionPage() {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  const tabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: '/institution',
      pathMatchEnd: true,
    },
    ...(perms?.canCreateProfile(organization)
      ? [
          {
            title: t('persons.title'),
            path: 'persons',
          },
        ]
      : []),
    ...(perms?.canListInstitutionCourses(organization)
      ? [
          {
            title: t('courses.title'),
            path: 'courses',
          },
        ]
      : []),
    ...(perms?.canListInstitutionGroups(organization)
      ? [
          {
            title: t('groups.title'),
            path: 'groups',
          },
        ]
      : []),
    ...(perms?.canListClasses(organization)
      ? [
          {
            title: t('classes.title'),
            path: 'classes',
            disabled: !perms.isOmniAdmin(),
          },
        ]
      : []),
    ...(perms?.canListSubjects(organization)
      ? [
          {
            title: t('subjects.title'),
            path: 'subjects',
          },
        ]
      : []),
    ...(perms?.canListSchoolyears(organization)
      ? [
          {
            title: t('schoolyears.title'),
            path: 'schoolyears',
          },
        ]
      : []),
  ];

  return (
    <BpPage title={t('institution.myInstitution')} isForbidden={false}>
      <NavigationTabs tabs={tabs} className='mb-6' />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
}
