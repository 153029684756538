import { Grid, GridRow, GridColumn } from '@bp/ui-components';
import { BpCard } from 'components/BpCard/BpCard';
import { BpSubpage } from 'components/BpSubpage/BpSubpage';
import { RunningMeetingsTable } from 'components/RunningMeetingsTable/RunningMeetingsTable';
import { useRunningOrganizationMeetings } from 'hooks/useCalendarEvents';
import { useTranslation } from 'react-i18next';
import styles from './RunningBBBMeetingsSubpage.module.scss';
import { useAuthClaims } from 'hooks/useAuthClaims';

export const RunningZoomMeetingsSubpage = () => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const { zoomMeetings, refetch } = useRunningOrganizationMeetings(organizationUuid);

  return (
    <BpSubpage>
      <Grid className={styles['running-zoom-meetings']}>
        <GridRow mobileGap='var(--grid-row-gap)'>
          <GridColumn width={6}>
            <BpCard>
              <div className={styles.text}>{t('meetings.currentlyRunning')}</div>
              <div className={styles.count}>9999</div>
            </BpCard>
          </GridColumn>
          <GridColumn width={6}>
            <BpCard>
              <div className={styles.text}>{t('meetings.licensedUsers')}</div>
              <div className={styles.count}>9999</div>
            </BpCard>
          </GridColumn>
        </GridRow>
        <GridRow>
          <BpCard header={{ headline: t('meetings.runningMeetings') }} noPadding>
            <RunningMeetingsTable
              meetings={zoomMeetings}
              type='zoom'
              onRefetch={() => refetch({ requestPolicy: 'network-only' })}
            />
          </BpCard>
        </GridRow>
      </Grid>
    </BpSubpage>
  );
};
