import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styles from './GroupSubpages.module.scss';
import { PersonsList } from '../../../components/PersonsList/PersonsList';
import { BpCard } from '../../../components/BpCard/BpCard';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { useRedirectToDmMessagePage } from '../../../hooks/matrix/useRedirectToDmMessagePage';
import {
  useBpCollaboratingOrganizationsQuery,
  useBpUpdateGroupsMutation,
  useGroupAsGroupsQuery,
} from '../../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { extractProfilesByRoles, ProfileType } from '../../../utils/extractProfilesByRoles';
import { EmptyState, Grid, GridColumn, GridRow, GroupsIcon, Modal } from '@bp/ui-components';
import { splitProfilesByOrganization } from '../../../utils/splitProfilesByOrganization';
import { GroupType } from '@bp/bp-graphql-types';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { CreateUserForm } from '../../../components/CreateUserForm/CreateUserForm';
import { useWindowDimensions } from 'utils/dimensions';

type GroupPersonsType = {
  title: string;
  data: ProfileType[];
  subHeadline: string;
};

export const GroupPersonsSubpage: FC = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const perms = usePermissionChecker();

  const { width } = useWindowDimensions();
  const isWide = width < 1024 || width > 1620;

  const { groupUuid } = useParams<{ groupUuid: string }>();
  const { redirectToDmMessagePage, loading } = useRedirectToDmMessagePage('group');
  const [roleLoading, setRoleLoading] = useState<string | null>(null);
  const [userModal, setUserModal] = useState<{ showModal: boolean; userUuid: string | null }>({
    showModal: false,
    userUuid: null,
  });
  const context = useMemoizedCacheTag('GROUP');
  const collaborationContext = useMemoizedCacheTag('COLLABORATING');
  const [, bpUpdateGroup] = useBpUpdateGroupsMutation();

  const [{ data }] = useGroupAsGroupsQuery({
    variables: { where: { uuid: groupUuid ?? '', type: GroupType.Group } },
    context,
  });

  const [{ data: collaboratingData }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
    context: collaborationContext,
  });

  const organizations = collaboratingData?.collaboratingOrganizations;
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const admins = data?.groups[0]?.admins ?? [];
  const editors = data?.groups[0]?.editors ?? [];
  const viewers = data?.groups[0]?.viewers ?? [];

  const splitEditors = splitProfilesByOrganization(
    editors.map((a) => {
      return { ...a, displayName: a.displayName ?? '' };
    }),
  );

  const splitViewers = splitProfilesByOrganization(
    viewers.map((a) => {
      return { ...a, displayName: a.displayName ?? '' };
    }),
  );

  const splitAdmins = splitProfilesByOrganization(
    admins.map((a) => {
      return { ...a, displayName: a.displayName ?? '' };
    }),
  );

  const schoolUuidsFromEditors = splitEditors ? Object.keys(splitEditors) : [];
  const schoolUuidsFromViewers = splitViewers ? Object.keys(splitViewers) : [];
  const schoolUuidsFromAdmins = splitAdmins ? Object.keys(splitAdmins) : [];

  const uniqueOrganizationUuids = [
    organizationUuid,
    ...new Set(
      [...schoolUuidsFromViewers, ...schoolUuidsFromEditors, ...schoolUuidsFromAdmins].filter(
        (uuid) => uuid !== organizationUuid,
      ),
    ),
  ];

  const toggleRole = async (uuid: string, isEditor: boolean) => {
    setRoleLoading(uuid);
    const newEditors = isEditor
      ? editors.filter((e) => e.uuid !== uuid).map((e) => e.uuid)
      : [...editors.map((e) => e.uuid), uuid];
    const newViewers = isEditor
      ? [...viewers.map((v) => v.uuid), uuid]
      : viewers.filter((v) => v.uuid !== uuid).map((e) => e.uuid);

    if (groupUuid && newEditors) {
      await bpUpdateGroup(
        {
          uuid: groupUuid,
          update: {
            editors: newEditors,
            viewers: newViewers,
            admins: admins.map((a) => a.uuid),
          },
        },
        context,
      );
    }
    setRoleLoading(null);
  };

  const getGridColumn = (group: GroupPersonsType, index: number, uuid: string) => {
    return (
      <GridColumn fitRowHeight key={uuid + '_' + index} width={isWide ? 3 : 6}>
        <BpCard
          fitParent
          noPadding
          header={{
            headline: group.title,
            subHeadline: group.subHeadline,
          }}
        >
          {group.data.length === 0 ? (
            <EmptyState
              icon={<GroupsIcon />}
              iconColor='var(--color-primary-light)'
              subtitle={t('persons.noPerson')}
              fitParent
              size='small'
              forcedHeight='250px'
            />
          ) : (
            <PersonsList
              maxHeight={uniqueOrganizationUuids.length > 1 ? 300 : undefined}
              organizationUuid={organizationUuid}
              persons={group.data.map((person) => ({
                uuid: person.uuid,
                organization: person.organization.uuid,
                displayName: person.displayName ?? '',
                userStatus: person.userStatus,
                isEditor: editors.some((editor) => editor.uuid === person.uuid),
                email: person.email ?? '',
                profileRole: (person.organizationRoles.find(
                  (r) =>
                    r === ProfileRoles.Teacher ||
                    r === ProfileRoles.Student ||
                    r === ProfileRoles.Parent ||
                    r === ProfileRoles.Other,
                ) ?? ProfileRoles.Other) as unknown as ProfileRoles,
                canMessage: perms?.canDirectMessageWithInGroup(
                  { uuid: groupUuid ?? '', organization: { uuid: organizationUuid } },
                  {
                    uuid: person.uuid,
                    organization: { uuid: organizationUuid },
                    role: person.organizationRoles.filter(
                      (r) =>
                        r === ProfileRoles.Teacher ||
                        r === ProfileRoles.Student ||
                        r === ProfileRoles.Parent ||
                        r === ProfileRoles.Other,
                    )[0] as unknown as ProfileRoles,
                  },
                ),
              }))}
              isEditable={perms?.canChangeBpGroupPermissions({
                uuid: groupUuid ?? '',
                organization: { uuid: organizationUuid },
              })}
              onChat={async (person) => {
                if (groupUuid && person.uuid) {
                  await redirectToDmMessagePage({
                    groupUuid: groupUuid,
                    targetProfileUuid: person.uuid,
                    targetProfileOrganizationUuid: '',
                    targetProfileRole: person.profileRole,
                  });
                }
              }}
              onToggle={(uuid, isEditor) => uuid && toggleRole(uuid, isEditor)}
              {...(perms?.canCreateUserInGroup({
                uuid: groupUuid ?? '',
                organization: { uuid: organizationUuid },
              })
                ? {
                    onProfile: (uuid) => {
                      setUserModal({ showModal: true, userUuid: uuid });
                    },
                  }
                : {})}
              loading={loading || roleLoading}
            />
          )}
        </BpCard>
      </GridColumn>
    );
  };

  return (
    <BpSubpage className={styles['group-persons-subpage']}>
      {uniqueOrganizationUuids.map((uuid) => {
        const {
          PARENT: parents,
          TEACHER: teachers,
          STUDENT: students,
          OTHER: others,
        } = extractProfilesByRoles({
          profiles: [
            ...(splitEditors && splitEditors[uuid] ? splitEditors[uuid] : []),
            ...(splitViewers && splitViewers[uuid] ? splitViewers[uuid] : []),
          ],
          profileRoles: [ProfileRoles.Teacher, ProfileRoles.Student, ProfileRoles.Parent, ProfileRoles.Other],
        });

        const groups: GroupPersonsType[] = [
          { title: t('students.title'), data: students ?? [], subHeadline: students.length.toString() },
          { title: t('teachers.title'), data: teachers ?? [], subHeadline: teachers.length.toString() },
          { title: t('common.parents'), data: parents ?? [], subHeadline: parents.length.toString() },
          { title: t('common.others'), data: others ?? [], subHeadline: others.length.toString() },
        ];

        const adminNames = splitAdmins ? (splitAdmins[uuid]?.map((a) => a.displayName) ?? ['-']) : ['-'];

        return (
          <div className={styles.organization} key={uuid}>
            <div className={styles.header}>
              <div className={styles.title}>
                {uuid === organizationUuid
                  ? t('common.myOrganization')
                  : (organizations?.find((o) => o.uuid === uuid)?.name ?? t('collaborations.cooperationSchool'))}
              </div>
              <div className={styles.text}>{t('common.administration')}:</div>
              <div className={styles.names}>{adminNames?.join(', ')}</div>
            </div>

            <Grid useFormGap>
              {isWide ? (
                <GridRow mobileGap='var(--spacing-4)'>
                  {groups.map((group, index) => getGridColumn(group, index, uuid))}
                </GridRow>
              ) : (
                <>
                  <GridRow spacingBottom='s'>
                    {getGridColumn(groups[0], 0, uuid)}
                    {getGridColumn(groups[1], 1, uuid)}
                  </GridRow>
                  <GridRow spacingTop='s'>
                    {getGridColumn(groups[2], 2, uuid)}
                    {getGridColumn(groups[3], 3, uuid)}
                  </GridRow>
                </>
              )}
            </Grid>
          </div>
        );
      })}

      {userModal.userUuid && (
        <Modal
          title={t('personsList.createUser')}
          onRequestClose={() => setUserModal({ showModal: false, userUuid: null })}
          isOpen={userModal.showModal}
          width='s'
        >
          <CreateUserForm
            uuid={userModal.userUuid}
            setModalClosed={() => setUserModal({ showModal: false, userUuid: null })}
          />
        </Modal>
      )}
    </BpSubpage>
  );
};
