import { FC, useMemo } from 'react';
import { Assignment, Assignment as TAssignment, SubmissionStatus } from '@bp/bp-graphql-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  Row,
  Table,
  TableColumns,
  useDefaultSorting,
} from '@bp/ui-components';
import { niceDate } from '../../utils/dateCalculations';
import { useBpDeleteAssignmentsMutation } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { BpLink } from 'components/BpLink/BpLink';
import { useMatrixAvailable } from 'hooks/matrix/useMatrixAvailable';
import { useRefreshOnEvent } from '../../hooks/matrix/useRefreshOnEvent';
import { useGetMatrixRooms } from '../../hooks/matrix/useGetMatrixRooms';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { NotificationReason } from '../../utils/matrixClient';
import { backendApi } from '../../utils/backendApi';
import { useConfirm } from 'hooks/useConfirm';

type AssignmentsTableProps = {
  data: TAssignment[];
  onAssignmentClick: (uuid: string) => void;
  onAssignmentEditClick?: (assignment: Assignment) => void;
};

export const AssignmentsTableTeacher: FC<AssignmentsTableProps> = ({
  data,
  onAssignmentClick,
  onAssignmentEditClick,
}) => {
  const { t } = useTranslation();
  const { courseUuid } = useParams();
  const perms = usePermissionChecker();
  const pimAuthClaims = useAuthClaims().pimAuthClaims;
  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'title', desc: false }]);

  const context = useMemoizedCacheTag('ASSIGNMENT');
  const [, deleteAssignment] = useBpDeleteAssignmentsMutation();

  const online = useMatrixAvailable();
  const refresh = useRefreshOnEvent();
  const rooms = useGetMatrixRooms();

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('assignments.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const handleDelete = async (assignment: Assignment) => {
    if (
      perms?.canDeleteAssignment({
        uuid: assignment.uuid,
        ownerUuid: assignment.holder.owner.uuid,
        groupUuid: assignment.holder.group.uuid,
        organization: assignment.holder.organization,
      })
    ) {
      const { error } = await deleteAssignment({ where: { uuid: assignment.uuid } }, context);
      if (!error) {
        showSuccessToast(t('delete.deleted', { type: t('assignments.task') }));

        void backendApi.notify({
          type: NotificationReason.AssignmentDeleted,
          groupUuid: courseUuid,
          subjectName: assignment.title,
        });
      }
    }
  };

  const createColumns = (): TableColumns<TAssignment>[] => {
    return [
      {
        id: 'title',
        header: t('common.name'),
        accessorKey: 'title',
        size: 250,
        canExpand: true,
        cell: ({ row }: { row: Row<TAssignment> }) => {
          let newMessages = false;
          row.original.submissions.forEach((submission) => {
            const room = rooms?.find((room) => {
              return room.name.includes(submission.uuid);
            });
            if (room && !newMessages) {
              newMessages = room.getRoomUnreadNotificationCount() > 0;
            }
          });
          return (
            <BpLink
              hasNews={newMessages}
              value={row.original.title}
              onNavigate={() => onAssignmentClick(row.original.uuid)}
              counter={row.original.submissions.filter((s) => s.status === SubmissionStatus.New).length}
              allowAfterDue={row.original.allowSubmissionAfterDueDate ?? false}
              isEditorOfCourse={true}
            />
          );
        },
      },
      {
        id: 'teachingunit',
        header: t('teachingUnits.titleSingular'),
        cell: (content) => <>{content.row.original.holder?.title}</>,
        accessorKey: 'holder.title',
        size: 250,
      },
      {
        id: 'visibleFrom',
        accessorKey: 'visibleFrom',
        header: t('common.activeFrom'),
        size: 150,
        cell: ({ cell }) => {
          return niceDate(cell.getValue(), 'medium');
        },
      },
      {
        id: 'dueDate',
        accessorKey: 'dueDate',
        header: t('common.due'),
        size: 150,
        cell: ({ cell }) => {
          return niceDate(cell.getValue(), 'medium');
        },
      },
      {
        id: 'created',
        accessorKey: 'created',
        header: t('common.created'),
        size: 150,
        cell: ({ cell }) => {
          return niceDate(cell.getValue(), 'medium');
        },
      },
    ];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(createColumns, [refresh, online, rooms, data]);
  const tableData = useMemo((): TAssignment[] => data, [data]);

  return (
    <>
      <Table<TAssignment>
        showSort
        showActionBar
        actionBarSettings={{
          marginBottom: 'var(--spacing-3)',
        }}
        breakpoint='580px'
        sorting={sorting}
        onSortingChange={onSortingChange}
        data={tableData}
        columns={memoizedColumns}
        hideHeader={tableData.length === 0}
        customPadding='var(--spacing-6)'
        customLastColSpacing='var(--spacing-4)'
        emptyStateSettings={{
          title: t('assignments.noAssignmentsYet'),
          subtitle: t('assignments.createHint'),
          hideIcon: true,
          padding: 'xl',
        }}
        lastCol={(row) => {
          if (
            perms?.canUpdateAssignment({
              uuid: row.original.uuid ?? '',
              ownerUuid: pimAuthClaims.getProfile().uuid,
              groupUuid: courseUuid ?? '',
              organization: { uuid: pimAuthClaims.getOrganizationUuid() },
            })
          )
            return (
              <Dropdown noPadding trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                <DropdownMenu
                  data-side='left'
                  data={[
                    {
                      label: t('common.edit'),
                      onClick: () => {
                        if (onAssignmentEditClick) {
                          onAssignmentEditClick(row.original);
                        }
                      },
                    },

                    {
                      type: 'ruler',
                    },
                    {
                      color: 'error',
                      label: t('delete.delete'),
                      onClick: async () => {
                        const res = await confirmDelete();
                        if (res) {
                          handleDelete(row.original);
                        }
                      },
                    },
                  ]}
                />
              </Dropdown>
            );
        }}
      />

      <ConfirmationDialog />
    </>
  );
};
