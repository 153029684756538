import {
  AddIcon,
  Button,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Select,
  SelectOptionType,
  TextArea,
} from '@bp/ui-components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { BpCalendarEventsQuery, useBpTeachingUnitsQuery } from '../../../../client/bp-graphql-client-defs';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { VirtualMeetingSettings } from './VirtualMeetingSettings';
import { TErrors } from '../../../AppointmentForm/AppointmentForm';
import { BpCard } from '../../../BpCard/BpCard';
import { isAfter, isBefore } from '../../../../utils/dateCalculations';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { AvailableService } from '../../../../hooks/useAvailableServicesForOrganization';

type AppointmentMetaProps = {
  availableServices: AvailableService[];
  holderUuid: string;
  canAssignTeachingUnit?: boolean;
  disableEventTypeValues: string[];
  calendarEvent: Pick<BpCalendarEventsQuery, 'calendarEvents'>['calendarEvents'][0] | undefined;
};

export const AppointmentMeta = ({
  calendarEvent,
  availableServices,
  holderUuid,
  canAssignTeachingUnit,
  disableEventTypeValues,
}: AppointmentMetaProps) => {
  const { t } = useTranslation();

  const { setFieldValue, setFieldTouched, handleChange, values, errors } = useFormikContext<TErrors>();

  const [textAreaVisible, setTextAreaVisible] = useState(values.desc !== '');

  const context = useMemoizedCacheTag('TEACHING_UNIT');

  const [teachingUnits] = useBpTeachingUnitsQuery({
    context: context,
    variables: {
      teachingUnitsWhere: {
        group: {
          uuid: holderUuid,
        },
      },
    },
  });

  const selectOptsTeachingUnit = useCreateSelectOptions(teachingUnits.data?.teachingUnits, 'uuid', 'title');

  const eventTypeOpts = useMemo(
    () =>
      [
        { value: 'event', label: t('events.categories.event') },
        { value: 'lesson', label: t('events.categories.lesson') },
        { value: 'exam', label: t('events.categories.exam') },
      ].filter((e) => !disableEventTypeValues.includes(e.value)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableEventTypeValues],
  );

  const locationTypeSelectOpts = useMemo(() => {
    if (availableServices.length > 0) {
      return [
        { value: 'onSite', label: t('events.onSite') },
        { value: 'hybrid', label: t('events.hybrid') },
        { value: 'virtual', label: t('events.virtual') },
      ];
    } else {
      return [{ value: 'onSite', label: t('events.onSite') }];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableServices]);

  const onEventTypeChange = async (ev: SelectOptionType) => {
    let categories = values.categories;
    const v = eventTypeOpts.map((item) => item.value);
    categories = categories?.filter((el) => !v.includes(el));
    await setFieldValue('categories', [...categories, ev.value]);
  };

  const onLocationChange = async (ev: SelectOptionType) => {
    let categories = values.categories;
    const v = locationTypeSelectOpts.map((item) => item.value);
    categories = categories.filter((el) => !v.includes(el));
    if (ev.value === 'hybrid') {
      await setFieldValue('categories', [...categories, 'onSite', 'virtual']);
    } else if (ev.value === 'onSite') {
      await setFieldValue('service', { value: '', label: '' });
      await setFieldValue('participantsCount', 0);
      await setFieldValue('duration', 0);
      await setFieldValue('categories', [...categories, ev.value]);
    } else {
      await setFieldValue('categories', [...categories, ev.value]);
    }
  };

  const onTeachingUnitChange = async (ev: SelectOptionType) => {
    await setFieldValue('teachingUnit', ev?.value);
  };

  const startTime = values.startTime;

  useEffect(() => {
    if (values.startDate.isSame(values.endDate, 'day') && values.startTime.isAfter(values.endTime, 'hour')) {
      setFieldValue('endTime', values.startTime.add(1, 'hour'), true).then((e) => e);
      setFieldTouched('endTime', true).then((e) => e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  return (
    <BpCard header={{ headline: t('appointments.general') }}>
      <Select
        className='mb-2'
        options={eventTypeOpts}
        onChange={async (e) => {
          await onEventTypeChange(e as unknown as SelectOptionType);
        }}
        defaultValue={
          eventTypeOpts.find((opt) =>
            calendarEvent?.categories ? calendarEvent?.categories.includes(opt.value as string) : false,
          ) ?? eventTypeOpts.find((opt) => opt.value === 'event')
        }
        name={'type'}
        label={t('common.type')}
        error={errors.lessonType}
        menuPosition='fixed'
      />

      {textAreaVisible && (
        <TextArea
          className='mb-2'
          label={t('common.description')}
          onChange={handleChange}
          value={values.desc}
          name='desc'
          rows={4}
          isScrollable
        />
      )}

      {!textAreaVisible && (
        <Button
          className='mb-5 mt-2'
          onClick={() => setTextAreaVisible(true)}
          icon={<AddIcon className='svg-icon white' />}
        >
          {t('common.addType', { type: t('common.description') })}
        </Button>
      )}

      <Grid useFormGap>
        <GridRow spacingBottom='none' breakpoint='phone'>
          <GridColumn width={6}>
            <DatePicker
              label={t('appointments.from')}
              onChange={async (e) => {
                if (!e) throw new Error('date is undefined');
                if (isAfter(e, values.endDate.toDate())) {
                  await setFieldValue('endDate', dayjs(e));
                  await setFieldTouched('endDate', true);
                }
                await setFieldValue('startDate', dayjs(e));
                await setFieldTouched('startDate', true);
              }}
              name={'startDate'}
              value={values.startDate.toDate()}
              showMonthYearDropdown
            />
          </GridColumn>
          <GridColumn width={6}>
            <Input
              name={`startTime`}
              label={t('common.start')}
              error={errors.startTime ? t('common.error') : undefined}
              value={values.startTime.format('HH:mm')}
              onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                const newTime = dayjs(values.startDate.format('YYYY-MM-DD') + event.target.value);
                await setFieldValue('startTime', newTime, true);
                await setFieldTouched('startTime', true);
              }}
              type='time'
            />
          </GridColumn>
        </GridRow>
        <GridRow spacingBottom='none' spacingTop='none' breakpoint='phone'>
          <GridColumn width={6}>
            <DatePicker
              label={t('appointments.to')}
              onChange={async (e) => {
                if (!e) throw new Error('date is undefined');
                if (isBefore(new Date(e), values.endDate.toDate())) {
                  await setFieldValue('startDate', dayjs(e), true);
                  await setFieldTouched('startDate', true);
                }
                await setFieldValue('endDate', dayjs(e), true);
                await setFieldTouched('endDate', true);
              }}
              name={'endDate'}
              value={values.endDate.toDate()}
              showMonthYearDropdown
            />
          </GridColumn>
          <GridColumn width={6}>
            <Input
              name={`endTime`}
              label={t('common.end')}
              error={errors.endTime ? 'Fehler' : undefined}
              value={values.endTime.format('HH:mm')}
              onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                const newTime = dayjs(values.endDate.format('YYYY-MM-DD') + event.target.value);
                await setFieldValue('endTime', newTime, true);
                await setFieldTouched('endTime', true);
              }}
              type='time'
            />
          </GridColumn>
        </GridRow>
      </Grid>

      {availableServices.length > 0 && (
        <Select
          className='mb-2'
          options={locationTypeSelectOpts}
          onChange={async (e) => {
            await onLocationChange(e as unknown as SelectOptionType);
            await setFieldTouched('lessonType');
          }}
          label={t('appointments.appointmentsType')}
          name={'lessonType'}
          defaultValue={
            locationTypeSelectOpts.find((opt) =>
              calendarEvent?.categories ? calendarEvent?.categories.includes(opt.value as string) : false,
            ) ?? locationTypeSelectOpts[0]
          }
          menuPosition='fixed'
        />
      )}

      {(values.categories.includes('virtual') || values.categories.includes('hybrid')) && (
        <VirtualMeetingSettings availableServices={availableServices} />
      )}

      {canAssignTeachingUnit ? (
        <Select
          options={selectOptsTeachingUnit}
          value={selectOptsTeachingUnit.find((tu) => {
            return tu.value === values.teachingUnit;
          })}
          onChange={async (e) => {
            await onTeachingUnitChange(e as unknown as SelectOptionType);
          }}
          label={t('teachingUnits.titleSingular')}
          name={'teachingUnit'}
          menuPosition='fixed'
        />
      ) : (
        <></>
      )}
    </BpCard>
  );
};
