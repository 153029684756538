import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { CombinedError } from 'urql';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import {
  useBpAssignmentsQuery,
  useBpCreateAssignmentMutation,
  useBpUpdateAssignmentsMutation,
} from '../../client/bp-graphql-client-defs';
import styles from './AssignmentForm.module.scss';
import { Button, Grid, GridColumn, GridRow, InplaceEdit } from '@bp/ui-components';
import { TeachingUnitSelect } from '../TeachingUnitSelect/TeachingUnitSelect';
import { DatesSelect } from '../DateSelect/DatesSelect';
import dayjs from 'dayjs';
import { FileUpload } from '../FileUpload/FileUpload';
import { BpCard } from '../BpCard/BpCard';
import { FileEntryTableType, FileTable } from '../FileTable/FileTable';
import classNames from 'classnames';
import { BpTipTap } from '../BpTipTap/BpTipTap';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { connectDay, fileEntriesConnect } from '../../utils/connectLib';
import { NotificationReason } from '../../utils/matrixClient';
import { backendApi } from '../../utils/backendApi';

export type AssignmentFormProps = {
  isModal?: boolean;
  assignmentUuid?: string;
  courseUuid?: string;
  currentTeachingUnitUuid: string;
  onClose: () => void;
  context: { additionalTypenames: string[] };
  isGroupEditor: boolean;
};

export type AssignmentFormType = {
  title: string;
  description: string;
  visibleFrom: Date;
  dueDate: Date;
  submissions: [];
  fileEntries: FileEntryTableType[];
  teachingUnitUuid: string;
  allowSubmissionAfterDueDate: boolean;
};

export function AssignmentForm({
  isModal = false,
  assignmentUuid,
  currentTeachingUnitUuid,
  courseUuid,
  onClose,
  context,
  isGroupEditor,
}: AssignmentFormProps) {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const pimAuthClaims = useAuthClaims().pimAuthClaims;

  const [, bpCreateAssignment] = useBpCreateAssignmentMutation();
  const [, updateAssignment] = useBpUpdateAssignmentsMutation();

  const [{ data }] = useBpAssignmentsQuery({
    context,
    variables: {
      where: {
        uuid: assignmentUuid,
      },
    },
  });

  const assignmentToEdit = data?.assignments.find((assignment) => assignment.uuid === assignmentUuid);

  const handleError = async (error: CombinedError | undefined, formikHelpers: FormikHelpers<AssignmentFormType>) => {
    if (error) {
      showErrorToast(error);
    } else {
      formikHelpers.resetForm();
      showSuccessToast(t('common.saved'));

      assignmentToEdit &&
        void backendApi.notify({
          type: NotificationReason.AssignmentUpdated,
          groupUuid: courseUuid,
          subjectUuid: assignmentUuid,
          subjectName: assignmentToEdit.title,
        });

      onClose();
    }
  };

  const now = new Date();
  const initialValues: AssignmentFormType = {
    description: assignmentToEdit?.material.text ?? '',
    teachingUnitUuid: currentTeachingUnitUuid,
    title: assignmentToEdit?.title ?? '',
    visibleFrom: dayjs(assignmentToEdit?.visibleFrom?.date).toDate() ?? now,
    dueDate: dayjs(assignmentToEdit?.dueDate?.date).toDate() ?? now,
    submissions: [],
    allowSubmissionAfterDueDate: assignmentToEdit?.allowSubmissionAfterDueDate ?? false,
    fileEntries: assignmentToEdit?.material.fileEntries ?? [],
  };

  const newAssignment = async (values: AssignmentFormType, formikHelpers: FormikHelpers<AssignmentFormType>) => {
    const dueDate = dayjs(values.dueDate).toISOString();
    const visibleFromDate = dayjs(values.visibleFrom).toISOString();
    if (
      perms?.canCreateAssignment({
        uuid: courseUuid ?? '',
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      })
    ) {
      const { error } = await bpCreateAssignment(
        {
          input: {
            title: values.title ?? '',
            course: { connect: { where: { node: { uuid: courseUuid } } } },
            dueDate: {
              ...connectDay(dueDate),
            },
            visibleFrom: { ...connectDay(visibleFromDate) },
            material: {
              create: {
                node: {
                  text: values.description,
                  textMediaType: 'text/plain',
                  fileEntries: fileEntriesConnect(values.fileEntries),
                },
              },
            },
            allowSubmissionAfterDueDate: values.allowSubmissionAfterDueDate,
            holder: {
              TeachingUnit: {
                connect: {
                  where: {
                    node: {
                      uuid: values.teachingUnitUuid,
                    },
                  },
                },
              },
            },
          },
        },
        context,
      );

      await handleError(error, formikHelpers);
    }
  };

  const editAssignment = async (values: AssignmentFormType, formikHelpers: FormikHelpers<AssignmentFormType>) => {
    if (
      perms?.canUpdateAssignment({
        uuid: assignmentUuid ?? '',
        ownerUuid: pimAuthClaims.getProfile().uuid,
        groupUuid: courseUuid ?? '',
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      })
    ) {
      const dueDate = dayjs(values.dueDate).toISOString();
      const visibleFrom = dayjs(values.visibleFrom).toISOString();
      const { error } = await updateAssignment({
        where: { uuid: assignmentUuid },
        update: {
          title: values.title,
          allowSubmissionAfterDueDate: values.allowSubmissionAfterDueDate,
          dueDate: {
            disconnect: {},
            ...connectDay(dueDate),
          },
          visibleFrom: {
            disconnect: {},
            ...connectDay(visibleFrom),
          },
          holder: {
            TeachingUnit: {
              connect: {
                where: { node: { uuid: values.teachingUnitUuid } },
              },
              disconnect: {},
            },
          },
          material: {
            update: {
              node: {
                text: values.description,
                fileEntries: [fileEntriesConnect(values.fileEntries)],
              },
            },
          },
        },
      });
      await handleError(error, formikHelpers);
    }
  };

  return (
    <div className={styles['assignment-form']}>
      <Formik<AssignmentFormType>
        onSubmit={assignmentToEdit ? editAssignment : newAssignment}
        initialValues={initialValues}
      >
        {({ values, setFieldValue, setFieldTouched }) => {
          return (
            <Form className={classNames('bp__form', isModal ? 'is-modal' : undefined)}>
              <div className={isModal ? 'bp__modal-header' : 'bp__form-header'}>
                <InplaceEdit
                  dense
                  className={styles.title}
                  name='title'
                  size='xxl'
                  onBlur={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  onChange={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  defaultValue={values.title}
                  placeholder={t('common.addTitle')}
                  backgroundColor={isModal ? 'white' : 'background'}
                />
                <div className={'bp__form-buttons'}>
                  <Button hierarchy='tertiary' type={'submit'} disabled>
                    {t('common.archive')}
                  </Button>
                  <Button hierarchy='secondary' onClick={onClose}>
                    {t('common.cancel')}
                  </Button>
                  <Button hierarchy='primary' type={'submit'}>
                    {t('common.save')}
                  </Button>
                </div>
              </div>

              <Grid>
                <GridRow mobileGap='var(--grid-column-gap)'>
                  <GridColumn width={8}>
                    <BpCard isEmbedded={isModal} header={{ headline: t('assignments.task') }}>
                      <BpTipTap
                        defaultValue={values.description}
                        onChange={async (value) => {
                          await setFieldValue('description', value);
                        }}
                        className={styles.description}
                        name={'description'}
                        required={false}
                        label={t('common.description')}
                        placeholder={t('common.addType', { type: t('common.description') })}
                      />
                      <FileTable
                        className={styles['file-list']}
                        mode={'edit'}
                        padding='0'
                        files={values.fileEntries ?? []}
                        onRenamed={async (uuid, newName) => {
                          const updated = values.fileEntries.map((fileEntry) => {
                            if (fileEntry.uuid === uuid) {
                              return { ...fileEntry, filename: newName };
                            }
                            return fileEntry;
                          });
                          await setFieldValue('fileEntries', updated);
                        }}
                        onDeleted={async (uuid) => {
                          await setFieldValue(
                            'fileEntries',
                            values.fileEntries.filter((fileEntry) => {
                              return fileEntry.uuid !== uuid;
                            }),
                          );
                        }}
                        isGroupEditor={isGroupEditor}
                      />
                      <FileUpload
                        onFileUpload={async (file) => {
                          await setFieldValue('fileEntries', [...values.fileEntries, file]);
                        }}
                      />
                    </BpCard>
                  </GridColumn>
                  <GridColumn width={4}>
                    <TeachingUnitSelect
                      teachingUnitUuid={values.teachingUnitUuid}
                      onChange={async (value) => {
                        await setFieldValue('teachingUnitUuid', value);
                        await setFieldTouched('teachingUnitUuid', true);
                      }}
                      isModal={isModal}
                      className={!isModal ? 'mb-7' : undefined}
                    />

                    <DatesSelect isModal={isModal} />
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
