import { FC } from 'react';
import { use_BpWorkMaterialsQuery } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useTranslation } from 'react-i18next';
import { BpCard } from 'components/BpCard/BpCard';
import { FileTable } from '../FileTable/FileTable';
import { niceDate } from '../../utils/dateCalculations';
import { BpHeader } from 'components/BpHeader/BpHeader';
import { BpText } from 'components/BpText/BpText';
import { BpTipTapText } from '../BpTipTapText/BpTipTapText';
import { Grid, GridColumn, GridRow } from '@bp/ui-components';

export type MaterialDetailProps = {
  uuid: string | undefined;
  onClose: () => void;
  isGroupEditor: boolean;
};

export const WorkMaterialDetail: FC<MaterialDetailProps> = ({ uuid, onClose, isGroupEditor }) => {
  const { t } = useTranslation();
  const queryContext = useMemoizedCacheTag('WORKMATERIAL');

  const [{ data }] = use_BpWorkMaterialsQuery({ variables: { where: { uuid: uuid ?? '' } }, context: queryContext });
  const material = data?.workMaterials[0];

  return (
    <>
      <BpHeader
        headline={material?.title ?? ''}
        actions={[
          {
            hierarchy: 'primary',
            callback: () => onClose(),
            text: t('common.back'),
          },
        ]}
      />
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={8}>
            <BpCard noPadding header={{ headline: t('common.content') }}>
              {material?.material.text && (
                <BpTipTapText className='mb-4' customPadding='0 var(--spacing-6)' content={material?.material.text} />
              )}
              <FileTable files={material?.material.fileEntries ?? []} mode={'show'} isGroupEditor={isGroupEditor} />
            </BpCard>
          </GridColumn>
          <GridColumn width={4}>
            <BpCard header={{ headline: t('workmaterials.relevantTimeFrame') }}>
              <BpText embedded>{`${niceDate(material?.visibleFrom?.date, 'short')} - ${niceDate(
                material?.visibleTo?.date,
                'short',
              )}`}</BpText>
            </BpCard>
          </GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};
