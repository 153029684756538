import { Input, Modal } from '@bp/ui-components';
import { useActivityProtocol } from '../../../hooks/matrix/useActivityProtocol';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationReason } from '../../../utils/matrixClient';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  submissionUuid: string;
};
export const RequestForEditModal = ({ isOpen, onClose, submissionUuid }: Props) => {
  const { sendMessage } = useActivityProtocol();
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const send = async () => {
    await sendMessage(submissionUuid, NotificationReason.RequestToFeedback, message);
    setMessage('');
  };

  const handleSubmit = () => {
    send();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title={t('submissions.requestToFeedback')}>
      <div>
        <Input
          onChange={handleChange}
          value={message}
          name={'title'}
          label={t('activityProtocol.addReason')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSubmit();
          }}
        />

        <ModalBottomButtons
          closeButton={{ callback: onClose }}
          submitButton={{ callback: handleSubmit }}
          isLoading={false}
          errors={[]}
        />
      </div>
    </Modal>
  );
};
