import { useTranslation } from 'react-i18next';
import { EmptyState, Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import { niceDate } from '../../utils/dateCalculations';
import { BpLink } from '../BpLink/BpLink';

type WorkMaterialsListProps = {
  workMaterial: WorkMaterialTableType[];
  onNavigate?: (uuid: string) => void;
  isGroupEditor: boolean;
};

export type WorkMaterialTableType = {
  uuid: string;
  updated: string;
  title: string;
};

const WorkMaterialsList = ({ workMaterial, onNavigate, isGroupEditor }: WorkMaterialsListProps) => {
  const { t } = useTranslation();

  const memoizedData = useMemo(() => {
    return workMaterial.map((wm) => {
      return { uuid: wm.uuid, title: wm.title, updated: niceDate(wm.updated, 'medium', 'short') ?? '' };
    });
  }, [workMaterial]);

  function createColumns(): TableColumns<WorkMaterialTableType>[] {
    return [
      {
        id: 'title',
        size: 450,
        canExpand: true,
        header: t('common.name'),
        accessorKey: 'title',
        cell: ({ row }) => {
          return (
            <BpLink
              value={row.original.title}
              onNavigate={() => onNavigate?.(row.original.uuid)}
              maxWidth='740px'
              isEditorOfCourse={isGroupEditor}
            />
          );
        },
      },
      {
        id: 'updated',
        accessorKey: 'updated',
        header: t('common.updatedAt'),
      },
    ];
  }

  return memoizedData.length === 0 ? (
    <EmptyState
      subtitle={t('workmaterials.noWorkmaterials')}
      fitParent
      padding='xl'
      size='small'
      forcedHeight='220px'
    />
  ) : (
    <Table<WorkMaterialTableType>
      columns={createColumns()}
      data={memoizedData}
      hideHeader={memoizedData.length === 0}
      customPadding='var(--spacing-6)'
      breakpoint='580px'
      customRowHeight='var(--list-row-height)'
    />
  );
};

export { WorkMaterialsList };
