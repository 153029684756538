import { useDaysQuery } from '../client/bp-graphql-client-defs';
import dayjs from 'dayjs';
import { useMemoizedCacheTag } from './useMemoizedCacheTag';
import { Day } from '@bp/bp-graphql-types';
import { CONNECT_DATE_TEMPLATE } from '../utils/connectLib';

export type DayType = Pick<Day, 'uuid' | 'date' | 'day' | 'month' | 'year' | 'dayOfWeek' | 'week' | 'schoolDay'>;

export const useDays = () => {
  const context = useMemoizedCacheTag('DAY');
  const [daysQueryResult] = useDaysQuery({
    variables: {
      where: {
        date: dayjs().format(CONNECT_DATE_TEMPLATE),
      },
    },
    context,
  });

  const today = daysQueryResult.data?.days[0];

  const isFuture = (day: DayType | undefined | null) => dayjs(day?.date).isAfter(today?.date, 'day');
  const isPast = (day: DayType | undefined | null) => dayjs(day?.date).isBefore(today?.date, 'day');
  const daysLeft = (day: DayType | undefined | null, returnNegative = false): number => {
    const remaining = dayjs(day?.date).diff(dayjs(today?.date), 'd');

    if (!returnNegative) {
      // return 0 when negative
      return remaining < 0 ? 0 : remaining;
    }

    // return any value
    return remaining;
  };

  return { today, daysLeft, isPast, isFuture };
};
