import { BpClassesType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import styles from './ClassesList.module.scss';
import { useTranslation } from 'react-i18next';
import { BpCard } from 'components/BpCard/BpCard';
import { ActionBar, Button, ClassesIllustration, EmptyState, ImportIcon } from '@bp/ui-components';
import { ClassesListModal } from './modals/ClassesListModal';
import { useMemo, useState } from 'react';
import { ClassesListItem } from './ClassesListItem';

export const ClassesList = () => {
  const { t } = useTranslation();
  // const { pimAuthClaims } = useAuthClaims();
  // const organizationUuid = pimAuthClaims.getOrganizationUuid();
  // const perms = usePermissionChecker();

  const [classesModalOpen, setClassesModalOpen] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<BpClassesType | null>(null);
  const [search, setSearch] = useState<string>('');

  // const [{ data }] = useBpClassesQuery({ variables: { where: { organization: { uuid: organizationUuid } } } });

  const classesMOCK: BpClassesType[] = [
    {
      uuid: '1',
      name: 'MOCK Klasse 1',
      shortName: 'MK1',
      grade: 1,
      color: '#DC143C',
      colorLabel: 'Crimson',
      source: 'Iphis123',
      tutor_1: 'Hans Mayer',
      tutor_2: 'Maria Müller',
      studentCount: 16,
      divisions: [
        [
          { name: 'Jungen', studentCount: 8 },
          { name: 'Mädchen', studentCount: 8 },
        ],
      ],
    },
    {
      uuid: '2',
      name: 'MOCK Klasse 2',
      shortName: 'MK2',
      grade: 2,
      color: '#FF69B4',
      colorLabel: 'HotPink',
      source: 'Iphis123',
      studentCount: 22,
      divisions: [
        [
          { name: 'evg.', studentCount: 7 },
          { name: 'kath.', studentCount: 4 },
        ],
        [
          { name: 'jüd.', studentCount: 2 },
          { name: 'mus.', studentCount: 3 },
          { name: 'hin.', studentCount: 1 },
        ],
      ],
    },
    {
      uuid: '3',
      name: 'MOCK Klasse 3a',
      shortName: 'MK3a',
      grade: 3,
      color: '#9370DB',
      colorLabel: 'MediumPurple',
      source: 'Iphis123',
      studentCount: 15,
      divisions: [
        [
          { name: 'Gruppe 1', studentCount: 2 },
          { name: 'Gruppe 2', studentCount: 2 },
          { name: 'Gruppe 3', studentCount: 2 },
          { name: 'Gruppe 4', studentCount: 2 },
          { name: 'Gruppe 5', studentCount: 2 },
        ],
      ],
    },
    {
      uuid: '4',
      name: 'MOCK Klasse 3b',
      shortName: 'MK3b',
      grade: 3,
      color: '#00FF00',
      colorLabel: 'Lime',
      source: 'Iphis123',
      studentCount: 123,
      divisions: [
        [
          { name: 'Orchester', studentCount: 22 },
          { name: 'Big Band', studentCount: 34 },
        ],
        [
          { name: 'Basketball', studentCount: 52 },
          { name: 'Volleyball', studentCount: 15 },
        ],
      ],
    },
    {
      uuid: '5',
      name: 'MOCK Klasse 4',
      shortName: 'MK4',
      grade: 4,
      color: '#00FF00',
      colorLabel: 'Lime',
      source: 'Iphis123',
      studentCount: 18,
    },
  ];

  const filteredClasses = useMemo(() => {
    return classesMOCK.filter((c) => c.name.includes(search));
  }, [search]);

  return (
    <div className={styles['classes-list']}>
      <ActionBar
        onGlobalFilterChange={(value) => setSearch(value)}
        showAddButton
        onAddClick={() => setClassesModalOpen(true)}
        extendedActionsRight={
          <Button icon={<ImportIcon />} hierarchy='tertiary'>
            {t('classes.import')}
          </Button>
        }
      />
      <BpCard className={styles['list-card']} noPadding preventCollapsible>
        {filteredClasses.length === 0 ? (
          <EmptyState
            icon={<ClassesIllustration />}
            title={t('classes.empty.title')}
            subtitle={t('classes.empty.subtitle')}
            forcedHeight='30vh'
          />
        ) : (
          filteredClasses.map((c) => (
            <ClassesListItem
              key={c.uuid}
              bpClass={c}
              onDelete={() => console.log('TODO: implement')}
              onEdit={(uuid) => {
                setSelectedClass(classesMOCK.find((c) => c.uuid === uuid) ?? null);
                setClassesModalOpen(true);
              }}
            />
          ))
        )}
      </BpCard>

      <ClassesListModal
        selectedClass={selectedClass ?? undefined}
        isOpen={classesModalOpen}
        onClose={() => {
          setClassesModalOpen(false);
          setSelectedClass(null);
        }}
      />
    </div>
  );
};
