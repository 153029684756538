import { createBrowserRouter, Outlet, RouteObject, RouterProvider } from 'react-router-dom';
import { BpAuthProvider } from './BpAuthProvider';
import { LoginPage } from './pages/Login/LoginPage';
import { App } from './App';
import { Home } from './pages/Home/Home';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { t } from 'i18next';
import { PageNotFoundPage } from './pages/PageNotFound/PageNotFoundPage';
import * as Sentry from '@sentry/react';
import { SignupPage } from './pages/Signup/SignupPage';
import { JoinPage } from 'pages/Join/JoinPage';
import { CoursesPage } from './pages/Courses/CoursesPage';
import { courseLoader, groupLoader } from './utils/pathPrefetcherAndLoader/courseLoader';
import { CoursePage } from './pages/Courses/CoursePage';
import {
  BpAssignmentsQuery,
  BpSubmissionsQuery,
  BpTeachingUnitsQuery,
  GroupAsCourseQuery,
  GroupAsGroupsQuery,
} from './client/bp-graphql-client-defs';
import { MediaLibraryPage } from './pages/MediaLibrary/MediaLibraryPage';
import { MaterialArchivePage } from './pages/MaterialArchive/MaterialArchivePage';
import { TemplatesPage } from './pages/Templates/TemplatesPage';
import { AccountsPage } from './pages/Accounts/AccountsPage';
import { MessagesPage } from './pages/Messages/MessagesPage';
import { MeetingsPage } from './pages/Meetings/MeetingsPage';
import { InstitutionPage } from './pages/Institution/InstitutionPage';
import { SettingsPage } from './pages/Settings/SettingsPage';
import { SettingsOverviewSubpage } from './pages/Settings/subpages/SettingsOverviewSubpage';
import { AbsenceReasonsSubpage } from './pages/Settings/subpages/AbsenceReasonsSubpage';
import { AdminPage } from './pages/Admin/AdminPage';
import { InstitutionAdminSubpage } from './pages/Admin/subpages/InstitutionAdminSubpage';
import { OrganizationsSubpage } from './pages/Admin/subpages/OrganizationsSubpage';
import { CourseOverviewSubpage } from './pages/Courses/subpages/CourseOverviewSubpage';
import { CourseLessonsSubpage } from './pages/Courses/subpages/CourseLessonsSubpage';
import { teachingUnitLoader } from './utils/pathPrefetcherAndLoader/teachingUnitLoader';
import { BpSubpage } from './components/BpSubpage/BpSubpage';
import { CourseAssignmentsSubpage } from './pages/Courses/subpages/CourseAssignmentsSubpage';
import { assignmentLoader } from './utils/pathPrefetcherAndLoader/assignmentLoader';
import { CourseAssignmentSubpage } from './pages/Courses/subpages/CourseAssignmentSubpage';
import { submissionLoader } from './utils/pathPrefetcherAndLoader/submissionLoader';
import { Submission } from './components/Submission/Submission';
import { PublicSubmission } from './components/Submission/PublicSubmission';
import { CourseWorkMaterialsSubpage } from './pages/Courses/subpages/CourseWorkMaterialsSubpage';
import { CourseAppointmentsSubpage } from './pages/Courses/subpages/CourseAppointmentsSubpage';
import { CoursePersonsSubpage } from './pages/Courses/subpages/CoursePersonsSubpage';
import { CourseAdminSubpage } from 'pages/Courses/subpages/CourseAdminSubpage';
import { ClassbookPage } from 'pages/Classbook/ClassbookPage';
import { GroupPersonsSubpage } from './pages/Groups/subpages/GroupPersonsSubpage';
import { GroupAppointmentsSubpage } from 'pages/Groups/subpages/GroupAppointmentsSubpage';
import { GroupsPage } from 'pages/Groups/GroupsPage';
import { GroupOverviewSubpage } from './pages/Groups/subpages/GroupOverviewSubpage';
import { GroupPage } from './pages/Groups/GroupPage';
import { GroupWorkMaterialsSubpage } from 'pages/Groups/subpages/GroupWorkMaterialsSubpage';
import { InstitutionSchoolyearsSubpage } from './pages/Institution/subpages/InstitutionSchoolyearsSubpage';
import { InstitutionOverviewSubpage } from './pages/Institution/subpages/InstitutionOverviewSubpage';
import { InstitutionPersonsSubpage } from './pages/Institution/subpages/InstitutionPersonsSubpage';
import { InstitutionGroupsSubpage } from './pages/Institution/subpages/InstitutionGroupsSubpage';
import { InstitutionCoursesSubpage } from './pages/Institution/subpages/InstitutionCoursesSubpage';
import { InstitutionSubjectsSubpage } from './pages/Institution/subpages/InstitutionSubjectsSubpage';
import { InstitutionCollaborationCoursesSubpage } from './pages/Settings/subpages/InstitutionCollaborationCoursesSubpage';
import { InstitutionCollaborationsSubpage } from './pages/Settings/subpages/InstitutionCollaborationsSubpage';
import { InstitutionCollaborationGroupsSubpage } from './pages/Settings/subpages/InstitutionCollaborationGroupsSubpage';
import { ProfilePage } from './pages/Profile/ProfilePage';
import { AboutPage } from 'pages/About/AboutPage';
import { RunningBBBMeetingsSubpage } from 'pages/Meetings/subpages/RunningBBBMeetingsSubpage';
import { RunningZoomMeetingsSubpage } from 'pages/Meetings/subpages/RunningZoomMeetingsSubpage';
import { InstitutionProcuratSubpage } from './pages/Settings/subpages/InstitutionProcuratSubpage';
import { InstitutionClassesSubpage } from 'pages/Institution/subpages/InstitutionClassesSubpage';

function unknownItem() {
  return {
    slug: 'unknown',
    title: 'Unknown Item',
  };
}

let wrappedBrowserRouter;
if (import.meta.env.VITE_APP_SENTRY_DSN !== 'false') {
  wrappedBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
} else {
  wrappedBrowserRouter = createBrowserRouter;
}

const router = wrappedBrowserRouter(
  [
    {
      path: '/login',
      element: (
        <BpAuthProvider>
          <LoginPage />
        </BpAuthProvider>
      ),
      handle: {
        crumb: () => ({
          slug: t('routes.login.slug'),
          title: t('routes.login.title'),
        }),
      },
    },
    {
      path: '/signup/:invitationToken',
      element: (
        <BpAuthProvider>
          <SignupPage />
        </BpAuthProvider>
      ),
    },
    {
      path: '/join/:conference',
      element: (
        <BpAuthProvider>
          <JoinPage />
        </BpAuthProvider>
      ),
    },
    {
      path: '/',
      Component: App,
      errorElement: <ErrorBoundary />,
      handle: {
        crumb: () => ({
          slug: '/',
          title: 'Dashboard',
        }),
      },
      children: [
        {
          index: true,
          element: <Home />,
        },

        {
          path: 'courses',
          element: <Outlet />,
          handle: {
            crumb: () => ({
              slug: 'courses',
              title: t('courses.title'),
            }),
          },
          children: [
            {
              index: true,
              element: <CoursesPage />,
            },
            {
              path: ':courseUuid',
              loader: ({ params }) => courseLoader(params.courseUuid),
              handle: {
                crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                  if (data?.groups[0])
                    return {
                      slug: `courses/${data.groups[0].uuid}`,
                      title: data.groups[0].name,
                    };
                  return unknownItem();
                },
              },
              element: <CoursePage />,
              children: [
                {
                  index: true,
                  id: 'course-overview',
                  element: <CourseOverviewSubpage />,
                },
                {
                  path: 'lessons',
                  element: <CourseLessonsSubpage />,
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/lessons`,
                          title: t('lessons.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                  children: [
                    {
                      path: ':teachingUnitUuid',
                      element: <CourseLessonsSubpage />,
                      loader: ({ params }) => teachingUnitLoader(params.teachingUnitUuid, params.courseUuid),
                      handle: {
                        crumb: ({
                          data,
                          teachingUnitUuid,
                        }: {
                          data: BpTeachingUnitsQuery;
                          teachingUnitUuid: string;
                        }) => {
                          const currentUnit = data.teachingUnits.find((teachingUnit) => {
                            return teachingUnit.uuid === teachingUnitUuid;
                          });
                          if (currentUnit)
                            return {
                              slug: `courses/${currentUnit.uuid}/lessons`,
                              title: `${currentUnit.title}`,
                            };
                          return unknownItem();
                        },
                      },
                    },
                  ],
                },

                {
                  path: 'assignments',
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/assignments`,
                          title: t('assignments.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                  children: [
                    {
                      index: true,
                      element: <CourseAssignmentsSubpage />,
                    },
                    {
                      path: ':assignmentUuid',
                      loader: ({ params }) => assignmentLoader(params.assignmentUuid),
                      handle: {
                        crumb: ({ data }: { data: BpAssignmentsQuery }) => {
                          if (data?.assignments[0]) {
                            return {
                              slug: `courses/${data.assignments[0].holder.group.uuid}/assignments/${data.assignments[0].uuid}`,
                              title: `${data.assignments[0].title}`,
                            };
                          }
                          return unknownItem();
                        },
                      },
                      children: [
                        {
                          index: true,
                          element: <CourseAssignmentSubpage />,
                        },
                        {
                          path: 'submissions/:submissionUuid',
                          loader: ({ params }) => submissionLoader(params.submissionUuid),
                          element: <Submission />,
                          handle: {
                            crumb: ({ data }: { data: BpSubmissionsQuery }) => {
                              if (data.submissions[0])
                                return {
                                  slug: `courses/${data.submissions[0].assignment[0].holder.group.uuid}/assignments/${data.submissions[0].assignment[0].uuid}/submissions/${data.submissions[0].uuid}`,
                                  title: `${t('submissions.titleSingular')} ${String.fromCharCode(8211)} ${
                                    data.submissions[0].owner.displayName
                                  }`,
                                };
                              return unknownItem();
                            },
                          },
                        },
                        {
                          path: 'submissions/public/:submissionUuid',
                          loader: ({ params }) => submissionLoader(params.submissionUuid),
                          element: <PublicSubmission />,
                          handle: {
                            crumb: ({ data }: { data: BpSubmissionsQuery }) => {
                              if (data.submissions[0])
                                return {
                                  slug: `courses/${data.submissions[0].assignment[0].holder.group.uuid}/assignments/${data.submissions[0].assignment[0].uuid}/submissions/public/${data.submissions[0].uuid}`,
                                  title: `${t('submissions.titleSingular')} ${String.fromCharCode(8211)} ${
                                    data.submissions[0].uuid
                                  }`,
                                };
                              return unknownItem();
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'workmaterials',
                  element: <Outlet />,
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/workmaterials`,
                          title: t('workmaterials.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                  children: [
                    { index: true, element: <CourseWorkMaterialsSubpage variant={'table'} /> },
                    {
                      path: ':uuid',
                      element: <CourseWorkMaterialsSubpage variant={'view'} />,
                      loader: ({ params }) => courseLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data, uuid }: { data: GroupAsCourseQuery; uuid: string }) => {
                          if (data?.groups[0]) {
                            return {
                              slug: `courses/${data.groups[0].uuid}/workmaterials/${uuid}`,
                              title: t('workmaterials.showMaterial'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                    {
                      path: 'add',
                      element: <CourseWorkMaterialsSubpage variant={'add'} />,
                      loader: ({ params }) => courseLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                          if (data?.groups[0]) {
                            return {
                              slug: `courses/${data.groups[0].uuid}/workmaterials/add`,
                              title: t('workmaterials.addMaterial'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                    {
                      path: 'edit/:uuid',
                      element: <CourseWorkMaterialsSubpage variant={'edit'} />,
                      loader: ({ params }) => courseLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data, uuid }: { data: GroupAsCourseQuery; uuid: string }) => {
                          if (data?.groups[0]) {
                            return {
                              slug: `courses/${data.groups[0].uuid}/workmaterials/edit/${uuid}`,
                              title: t('workmaterials.editMaterial'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                  ],
                },
                {
                  path: 'appointments',
                  element: <CourseAppointmentsSubpage />,
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/appointments`,
                          title: t('appointments.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                  children: [
                    {
                      path: ':add',
                      element: <CourseAppointmentsSubpage />,
                      loader: ({ params }: { params: { groupUuid: string } }) => courseLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                          if (data?.groups[0]) {
                            return {
                              slug: `courses/${data.groups[0].uuid}/appointments/add`,
                              title: t('appointments.addAppointment'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                    {
                      path: ':edit/:uuid',
                      element: <CourseAppointmentsSubpage />,
                      loader: ({ params }) => courseLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data, uuid }: { data: GroupAsCourseQuery; uuid: string }) => {
                          if (data?.groups[0]) {
                            return {
                              slug: `courses/${data.groups[0].uuid}/appointments/edit/${uuid}`,
                              title: t('appointments.editAppointment'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                  ],
                },
                {
                  path: 'persons',
                  element: <CoursePersonsSubpage />,
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/persons`,
                          title: t('persons.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                },
                {
                  path: 'cloud',
                  element: (
                    <BpSubpage>
                      <></>
                    </BpSubpage>
                  ),
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/cloud`,
                          title: 'Cloud',
                        };
                      }
                      return unknownItem();
                    },
                  },
                },
                {
                  path: 'admin',
                  element: <CourseAdminSubpage />,
                  loader: ({ params }) => courseLoader(params.courseUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsCourseQuery }) => {
                      if (data?.groups[0]) {
                        return {
                          slug: `courses/${data.groups[0].uuid}/admin`,
                          title: 'Admin',
                        };
                      }
                      return unknownItem();
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'groups',
          element: <Outlet />,
          handle: {
            crumb: () => ({
              slug: 'groups',
              title: t('groups.title'),
            }),
          },
          children: [
            {
              index: true,
              element: <GroupsPage />,
            },
            {
              path: ':groupUuid',
              loader: ({ params }) => groupLoader(params.groupUuid),
              handle: {
                crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                  if (data.groups[0]) return { slug: `groups/${data.groups[0].uuid}`, title: data.groups[0].name };
                  return unknownItem();
                },
              },
              element: <GroupPage />,
              children: [
                {
                  index: true,
                  id: 'group-overview',
                  element: <GroupOverviewSubpage />,
                },
                {
                  path: 'workmaterials',
                  element: <Outlet />,
                  loader: ({ params }) => groupLoader(params.groupUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                      if (data.groups[0]) {
                        return {
                          slug: `groups/${data.groups[0].uuid}/workmaterials`,
                          title: t('workmaterials.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                  children: [
                    {
                      index: true,
                      element: <GroupWorkMaterialsSubpage variant={'table'} />,
                    },
                    {
                      path: 'add',
                      element: <GroupWorkMaterialsSubpage variant={'add'} />,
                      loader: ({ params }) => groupLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                          if (data.groups[0]) {
                            return {
                              slug: `groups/${data.groups[0].uuid}/workmaterials/add`,
                              title: t('workmaterials.addMaterial'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                    {
                      path: ':uuid/edit',
                      element: <GroupWorkMaterialsSubpage variant={'edit'} />,
                      loader: ({ params }) => groupLoader(params.uuid),
                      handle: {
                        crumb: ({ data, uuid }: { data: GroupAsGroupsQuery; uuid: string }) => {
                          if (data.groups[0]) {
                            return {
                              slug: `groups/${data.groups[0].uuid}/workmaterials/${uuid}/:edit`,
                              title: t('workmaterials.editMaterial'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                    {
                      path: ':uuid',
                      element: <GroupWorkMaterialsSubpage variant={'detail'} />,
                      loader: ({ params }) => groupLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                          if (data.groups[0]) {
                            return {
                              slug: `groups/${data.groups[0].uuid}/workmaterials/add`,
                              title: t('workmaterials.showMaterial'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                  ],
                },
                {
                  path: 'appointments',
                  element: <GroupAppointmentsSubpage />,
                  loader: ({ params }) => groupLoader(params.groupUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                      if (data.groups[0]) {
                        return {
                          slug: `groups/${data.groups[0].uuid}/appointments`,
                          title: t('appointments.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                  children: [
                    {
                      path: ':add',
                      element: <GroupAppointmentsSubpage />,
                      loader: ({ params }) => groupLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                          if (data.groups[0]) {
                            return {
                              slug: `groups/${data.groups[0].uuid}/appointments/add`,
                              title: t('appointments.addAppointment'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                    {
                      path: ':edit/:uuid',
                      element: <GroupAppointmentsSubpage />,
                      loader: ({ params }) => groupLoader(params.groupUuid),
                      handle: {
                        crumb: ({ data, uuid }: { data: GroupAsGroupsQuery; uuid: string }) => {
                          if (data.groups[0]) {
                            return {
                              slug: `groups/${data.groups[0].uuid}/appointments/edit/${uuid}`,
                              title: t('appointments.editAppointment'),
                            };
                          }
                          return unknownItem();
                        },
                      },
                    },
                  ],
                },
                {
                  path: 'persons',
                  element: <GroupPersonsSubpage />,
                  loader: ({ params }) => groupLoader(params.groupUuid),
                  handle: {
                    crumb: ({ data }: { data: GroupAsGroupsQuery }) => {
                      if (data.groups[0]) {
                        return {
                          slug: `groups/${data.groups[0].uuid}/persons`,
                          title: t('persons.title'),
                        };
                      }
                      return unknownItem();
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'classbook/:dateParam?',
          element: <ClassbookPage />,
          handle: {
            crumb: () => ({
              slug: 'classbook',
              title: t('classbook.title'),
            }),
          },
        },

        {
          path: 'mediaLibrary',
          element: <MediaLibraryPage />,
          handle: {
            crumb: () => ({
              slug: 'mediaLibrary',
              title: 'Medien',
            }),
          },
        },

        {
          path: 'materialArchive',
          element: <MaterialArchivePage />,
          handle: {
            crumb: () => ({
              slug: 'materialArchive',
              title: 'Material Archive',
            }),
          },
        },

        {
          path: 'templates',
          element: <TemplatesPage />,
          handle: {
            crumb: () => ({
              slug: 'templates',
              title: t('templates.title'),
            }),
          },
        },

        {
          path: 'accounts',
          element: <AccountsPage />,
          handle: {
            crumb: () => ({
              slug: 'accounts',
              title: 'Accounts',
            }),
          },
        },

        {
          path: 'messages',
          element: <MessagesPage />,
          handle: {
            crumb: () => ({
              slug: 'messages',
              title: t('messages.title'),
            }),
          },
          children: [
            {
              path: ':roomId',
              element: <MessagesPage />,
            },
          ],
        },

        {
          path: 'meetings',
          element: <Outlet />,
          children: [
            {
              element: <MeetingsPage />,
              handle: {
                crumb: () => ({
                  slug: 'meetings',
                  title: t('meetings.title'),
                }),
              },
              children: [
                {
                  index: true,
                  element: <RunningBBBMeetingsSubpage />,
                  handle: {
                    crumb: () => ({
                      slug: 'meetings',
                      title: t('meetings.runningBBB'),
                    }),
                  },
                },
                {
                  path: 'zoom',
                  element: <RunningZoomMeetingsSubpage />,
                  handle: {
                    crumb: () => ({
                      slug: 'meetings',
                      title: t('meetings.runningZoom'),
                    }),
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'institution',
          element: <InstitutionPage />,
          handle: {
            crumb: () => ({
              slug: 'institution',
              title: t('institution.myInstitution'),
            }),
          },
          children: [
            {
              index: true,
              id: 'institution-overview',
              element: <InstitutionOverviewSubpage />,
            },

            {
              path: 'persons',
              element: <InstitutionPersonsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'persons',
                  title: t('persons.title'),
                }),
              },
            },
            {
              path: 'groups',
              element: <InstitutionGroupsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'groups',
                  title: t('groups.title'),
                }),
              },
            },
            {
              path: 'courses',
              element: <InstitutionCoursesSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'courses',
                  title: t('courses.title'),
                }),
              },
            },
            {
              path: 'classes',
              element: <InstitutionClassesSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'classes',
                  title: t('classes.title'),
                }),
              },
            },
            {
              path: 'subjects',
              element: <InstitutionSubjectsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'subjects',
                  title: t('subjects.title'),
                }),
              },
            },
            {
              path: 'schoolyears',
              element: <InstitutionSchoolyearsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'schoolyears',
                  title: t('schoolyears.title'),
                }),
              },
            },
          ],
        },

        {
          path: 'settings',
          element: <SettingsPage />,
          handle: {
            crumb: () => ({
              slug: 'institution',
              title: t('institution.myInstitution'),
            }),
          },
          children: [
            {
              index: true,
              id: 'settings-overview',
              element: <SettingsOverviewSubpage />,
            },
            {
              path: 'absence-reasons',
              element: <AbsenceReasonsSubpage />,
            },
            {
              path: 'collaborations',
              element: <InstitutionCollaborationsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'collaborations',
                  title: t('collaborations.title'),
                }),
              },
            },
            {
              path: 'collaboration-groups',
              element: <InstitutionCollaborationGroupsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'groups',
                  title: t('groups.title'),
                }),
              },
            },
            {
              path: 'collaboration-courses',
              element: <InstitutionCollaborationCoursesSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'courses',
                  title: t('courses.title'),
                }),
              },
            },
            {
              path: 'procurat',
              element: (
                <>
                  <InstitutionProcuratSubpage />
                </>
              ),
              handle: {
                crumb: () => ({
                  slug: 'procurat',
                  title: t('institution.procurat'),
                }),
              },
            },
          ],
        },
        {
          path: 'profile',
          element: <ProfilePage />,
          handle: {
            crumb: () => ({
              slug: 'profile',
              title: t('profiles.titleSingular'),
            }),
          },
        },
        {
          path: 'admin',
          element: <AdminPage />,
          handle: {
            crumb: () => ({
              slug: 'admin',
              title: t('admin.title'),
            }),
          },
          children: [
            {
              path: 'admin',
              element: <InstitutionAdminSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'admin',
                  title: t('institution.admin'),
                }),
              },
            },
            {
              path: 'organizations',
              element: <OrganizationsSubpage />,
              handle: {
                crumb: () => ({
                  slug: 'organizations',
                  title: t('organizations.title.plural'),
                }),
              },
            },
          ],
        },
        {
          path: 'about',
          element: <AboutPage />,
          handle: {
            crumb: () => ({
              slug: 'about',
              title: t('common.about'),
            }),
          },
        },
      ],
    },

    {
      path: '*',
      element: <PageNotFoundPage />,
    },
  ] as RouteObject[],
  { basename: import.meta.env.VITE_APP_BASEPATH },
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
