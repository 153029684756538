import { BpClassesType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import styles from './ClassesListItem.module.scss';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  DeleteIcon,
  DivisionIllustration,
  EditIcon,
  EmptyState,
  ExclamationmarkIcon,
  Tooltip,
} from '@bp/ui-components';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type ClassesListItemProps = {
  bpClass: BpClassesType;
  onDelete: (uuid: string) => void;
  onEdit: (uuid: string) => void;
};

export const ClassesListItem = ({ bpClass, onDelete, onEdit }: ClassesListItemProps) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [bodyHeight, setBodyHeight] = useState<number>(0);

  const bodyRef = useRef<HTMLDivElement>(null);

  const classes = classNames(styles['classes-list-item'], { [styles.expanded]: isExpanded });

  let groupStudentCount = 0;
  bpClass.divisions?.forEach((d) => d.forEach((g) => (groupStudentCount = groupStudentCount + g.studentCount)));

  useEffect(() => {
    setBodyHeight(isExpanded && bodyRef.current ? bodyRef.current.scrollHeight : 0);
  }, [isExpanded]);

  return (
    <div className={classes} style={{ height: `calc(${bodyHeight}px + var(--spacing-8))` }}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.name}>{bpClass.name}</div>
          <div className={styles.count}>
            ({t('classes.studentCount', { count: bpClass.studentCount })})
            {groupStudentCount < (bpClass.studentCount ?? 0) && (
              <Tooltip triggerClass={styles.warning} content={t('classes.inconsistentCount')}>
                {<ExclamationmarkIcon />}{' '}
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          <Button hierarchy='ghost' className='mr-2' icon={<DeleteIcon />} onClick={() => onDelete(bpClass.uuid!)} />
          <Button hierarchy='ghost' className='mr-2' icon={<EditIcon />} onClick={() => onEdit(bpClass.uuid!)} />
          <Button
            hierarchy='ghost'
            icon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={() => setIsExpanded((e) => !e)}
          />
        </div>
      </div>
      <div ref={bodyRef} className={styles.body}>
        <div className={styles['info-wrapper']}>
          <div className={styles.info}>
            <div className={styles.color}>
              <div className={styles.dot} style={{ backgroundColor: bpClass.color ?? 'white' }}></div>
              <div>{bpClass.colorLabel ?? '---'}</div>
            </div>
            <div>{t('common.color')}</div>
          </div>
          <div className={styles.info}>
            <div>{bpClass.source ?? '---'}</div>
            <div>{t('classes.source')}</div>
          </div>
          <div className={styles.info}>
            <div>{bpClass.tutor_1 ?? '---'}</div>
            <div>{t('classes.tutor')} 1</div>
          </div>
          <div className={styles.info}>
            <div>{bpClass.tutor_2 ?? '---'}</div>
            <div>{t('classes.tutor')} 2</div>
          </div>
        </div>
        <div className={styles['divisions-wrapper']}>
          <div className={styles.top}>
            {t('divisions.title')} <Button icon={<EditIcon />} hierarchy='ghost' />
          </div>
          <div className={styles.divisions}>
            {!bpClass.divisions || bpClass.divisions.length === 0 ? (
              <EmptyState size='small' icon={<DivisionIllustration />} title={t('divisions.noDivisions')} />
            ) : (
              bpClass.divisions?.map((d, index) => {
                return (
                  <div
                    key={(bpClass.uuid ?? '0') + index}
                    className={styles.division}
                    style={{ gridTemplateColumns: `repeat(${d.length}, 1fr)` }}
                  >
                    {d.map((g) => {
                      return (
                        <div key={(bpClass.uuid ?? '0') + g.name} className={styles.group}>
                          <div className={styles.name}>{g.name}</div>
                          <div className={styles.count}>({g.studentCount})</div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
