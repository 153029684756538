import { FC, useMemo } from 'react';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useQuery } from '@tanstack/react-query';
import { backendApi } from '../../../utils/backendApi';
import { Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';

type ProcuratOrganization = {
  id: string;
  name: string;
  aktiv: boolean;
};
type ProcuratPerson = {
  id: number;
  familyId: number;
  nationalityId: number;
  addressId: number;
  birthCountryId: number;
  languageId: number;
  religionId: number;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  birthPlace: string;
  allFirstNames: string;
  birthName: string;
  academicTitle: string;
  namePrefix: string;
  nobilityTitle: string;
  salutationA: string;
  salutationB: string;
  jobTitle: string;
  comment: string;
  maritalStatus: string;
  familyRole: string;
  email: string;
  deathDate: string;
};
type ProcuratGroup = {
  id: string;
  name: string;
  aktiv: boolean;
};

export const InstitutionProcuratSubpage: FC = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();

  const { isFetching: orgFetching, data: orgaData } = useQuery({
    queryKey: ['procuratOrga'],
    queryFn: async () => {
      const response = await backendApi.fetch(`/procurat/organization`);
      return await response.json();
    },
  });

  const procuratOrgaId = 'org_xeycdvloqtou8e9xace1lau2';

  const { isFetching: personFetching, data: personData } = useQuery({
    queryKey: ['procuratPerson'],
    queryFn: async () => {
      const response = await backendApi.fetch(`/procurat/organization/${procuratOrgaId}/person`);
      return await response.json();
    },
  });
  const { isFetching: groupFetching, data: groupData } = useQuery({
    queryKey: ['procuratGroup'],
    queryFn: async () => {
      const response = await backendApi.fetch(`/procurat/organization/${procuratOrgaId}/group`);
      return await response.json();
    },
  });

  const orgaColumns: TableColumns<ProcuratOrganization>[] = useMemo(() => {
    return [
      {
        id: 'id',
        accessorKey: 'id',
        size: 250,
      },
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 200,
        canExpand: true,
      },
      {
        id: 'active',
        accessorKey: 'active',
        header: t('common.status'),
        type: 'boolean',
        size: 150,
      },
    ];
  }, [t]);
  const personColumns: TableColumns<ProcuratPerson>[] = useMemo(() => {
    return [
      {
        id: 'id',
        accessorKey: 'id',
        size: 80,
      },
      {
        header: t('common.name'),
        id: 'fistName',
        accessorKey: 'firstName',
        size: 200,
      },
      {
        header: t('common.name'),
        id: 'lastName',
        accessorKey: 'lastName',
        size: 200,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: t('common.status'),
        size: 150,
        canExpand: true,
      },
      {
        id: 'birthDate',
        accessorKey: 'birthDate',
        header: t('common.status'),
        type: 'date',
        size: 150,
      },
    ];
  }, [t]);
  const groupColumns: TableColumns<ProcuratGroup>[] = useMemo(() => {
    return [
      {
        id: 'id',
        accessorKey: 'id',
        size: 250,
      },
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 200,
        canExpand: true,
      },
      {
        id: 'active',
        accessorKey: 'active',
        header: t('common.status'),
        type: 'boolean',
        size: 150,
      },
    ];
  }, [t]);

  const orgaTableData: ProcuratOrganization[] = useMemo(() => {
    if (orgFetching) return [];
    return orgaData;
  }, [orgaData, orgFetching]);
  const personTableData: ProcuratPerson[] = useMemo(() => {
    if (personFetching) return [];
    return [];
  }, [personData, personFetching]);
  const groupTableData: ProcuratGroup[] = useMemo(() => {
    if (groupFetching) return [];
    return groupData.systemYear;
  }, [groupData, groupFetching]);

  return (
    <BpSubpage isForbidden={!perms?.isOmniAdmin()}>
      <>
        <Table<ProcuratOrganization> columns={orgaColumns} data={orgaTableData} hideHeader={false} />
        <Table<ProcuratPerson> columns={personColumns} data={personTableData} hideHeader={false} />
        <Table<ProcuratGroup> columns={groupColumns} data={groupTableData} hideHeader={false} />
      </>
    </BpSubpage>
  );
};
