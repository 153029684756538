import { SortDirection } from '@bp/bp-graphql-types';
import { AddIcon, Button, Grid, GridRow, Modal, SearchInput, SpinnerIcon } from '@bp/ui-components';
import {
  use_BpTeachingUnitsByGroupQuery,
  useBpDeleteWorkMaterialsMutation,
} from '../../../client/bp-graphql-client-defs';
import { BpCard } from '../../../components/BpCard/BpCard';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { TeachingUnitContext } from '../../../context/TeachingUnitContext';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { ChangeEvent, FC, Suspense, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { niceDate } from '../../../utils/dateCalculations';
import { CourseWorkMaterialsForm } from '../../../components/WorkMaterialsForm/CourseWorkMaterialsForm';
import { CourseWorkMaterialsTable } from '../../../components/WorkMaterialsTable/CourseWorkMaterialsTable';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { WorkMaterialDetail } from '../../../components/WorkMaterialDetail/WorkMaterialDetail';
import dayjs from 'dayjs';
import { NotificationReason } from '../../../utils/matrixClient';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';
import { useConfirm } from '../../../hooks/useConfirm';
import { backendApi } from '../../../utils/backendApi';
import { WorkingMaterialsQueryType } from './types';

export const CourseWorkMaterialsSubpage: FC<{ variant: 'add' | 'edit' | 'view' | 'table' }> = ({ variant }) => {
  const { t } = useTranslation();
  const { courseUuid, uuid } = useParams();

  const navigate = useNavigate();
  // TODO use organization from course!
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('TEACHING_UNIT');
  const { teachingUnitUuid } = useContext(TeachingUnitContext);

  const [searchValue, setSearchValue] = useState('');
  const [workmaterialModalOpen, setWorkmaterialModalOpen] = useState(false);
  const [currentWorkmaterialUuid, setCurrentWorkmaterialUuid] = useState('');

  const isGroupEditor = !!perms?.isGroupEditor({
    uuid: courseUuid ?? '',
    organization: { uuid: organizationUuid },
  });

  const [workMaterials] = use_BpTeachingUnitsByGroupQuery({
    context,
    variables: {
      where: {
        uuid: courseUuid,
      },
      workMaterialsOptions: {
        sort: [
          {
            updated: SortDirection.Asc,
          },
        ],
      },
    },
  });

  const materials: WorkingMaterialsQueryType[] = workMaterials.data?.groups
    ? workMaterials.data?.groups
        ?.map((g) => {
          return g.teachingUnits?.map((t) =>
            t.workMaterials.map((item) => {
              return {
                ...item,
                timeFrame: `${niceDate(item.visibleFrom?.date, 'short')} - ${niceDate(item.visibleTo?.date, 'short')}`,
              };
            }),
          );
        })
        .flat(2)
    : [];

  const activeMaterials: WorkingMaterialsQueryType[] = [];
  const pastMaterials: WorkingMaterialsQueryType[] = [];
  const futureMaterials: WorkingMaterialsQueryType[] = [];

  const day = dayjs();
  if (materials && materials.length > 0) {
    materials.forEach((material) => {
      if (
        dayjs(material.visibleFrom?.date).isSame(day, 'day') ||
        dayjs(day).isBetween(material.visibleFrom?.date, material.visibleTo?.date, 'day') ||
        dayjs(material.visibleTo?.date).isSame(day, 'day')
      ) {
        activeMaterials.push(material);
      } else if (dayjs(material.visibleFrom?.date).isBefore(day, 'day')) {
        pastMaterials.push(material);
      } else {
        futureMaterials.push(material);
      }
    });
  }

  const handleWorkMaterialClick = (uuid: string) => {
    navigate(`/courses/${courseUuid}/workmaterials/${uuid}`);
  };

  const handleWorkMaterialEditClick = (uuid: string) => {
    setCurrentWorkmaterialUuid(uuid);
    setWorkmaterialModalOpen(true);
  };

  const handleAddWorkMaterial = () => {
    navigate(`/courses/${courseUuid}/workmaterials/add`);
  };

  const hasPermission = courseUuid
    ? perms?.canCreateWorkmaterial({
        uuid: courseUuid,
        organization: { uuid: organizationUuid },
      })
    : false;

  const filteredIrelevantMaterials = isGroupEditor
    ? [...futureMaterials, ...pastMaterials]
    : [...futureMaterials, ...pastMaterials].filter(
        (material) =>
          dayjs(material.publicFrom?.date).startOf('day').isBefore(dayjs(material.visibleFrom?.date).startOf('day')) ||
          material.visibleAfterVisibleTo,
      );

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('workmaterials.title'), context: 'neutrum' }),
    defaultConfirmText: t('delete.delete'),
  });

  const wmcontext = useMemoizedCacheTag('WORKMATERIAL');
  const [, deleteWorkMaterial] = useBpDeleteWorkMaterialsMutation();

  const handleDelete = async (uuid: string, title: string) => {
    const confirm = await confirmDelete();
    if (confirm) {
      const res = await deleteWorkMaterial(
        {
          where: {
            uuid,
          },
        },
        wmcontext,
      );
      if (res.error) {
        showErrorToast(res.error);
      } else {
        showSuccessToast(t('common.success'));

        void backendApi.notify({
          type: NotificationReason.CourseMaterialDeleted,
          groupUuid: courseUuid ?? '',
          subjectName: title,
        });
      }
    }
  };

  const handleClose = async (uuid?: string, title?: string) => {
    if (courseUuid) {
      // send matrix notification
      const notificationBody: {
        type: NotificationReason;
        subjectUuid?: string;
        groupUuid: string;
        subjectName: string;
      } = {
        type: variant === 'add' ? NotificationReason.CourseMaterialCreated : NotificationReason.CourseMaterialUpdated,
        groupUuid: courseUuid,
        subjectName: title ?? '',
      };

      if (variant === 'edit' && uuid) {
        notificationBody.subjectUuid = uuid;
      }

      void backendApi.notify(notificationBody);
    }
    navigate(`/courses/${courseUuid}/workmaterials/`);
  };

  return (
    <BpSubpage>
      {variant === 'add' && courseUuid && hasPermission && (
        <CourseWorkMaterialsForm
          context={context}
          onClose={handleClose}
          currentTeachingUnitUuid={teachingUnitUuid ?? ''}
        />
      )}
      {variant === 'edit' && courseUuid && hasPermission && (
        <CourseWorkMaterialsForm
          context={context}
          onClose={(uuid, title) => handleClose(uuid, title)}
          currentTeachingUnitUuid={teachingUnitUuid ?? ''}
          workMaterialUuid={uuid ?? undefined}
        />
      )}
      {variant === 'view' && courseUuid && (
        <WorkMaterialDetail
          onClose={() => navigate(`/courses/${courseUuid}/workmaterials/`)}
          uuid={uuid}
          isGroupEditor={isGroupEditor}
        />
      )}
      {variant === 'table' && (
        <Grid>
          <GridRow mobileGap='var(--grid-column-gap)'>
            <SearchInput
              name='search'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(event.target.value);
              }}
              value={searchValue}
            />

            {perms?.canCreateWorkmaterial({ uuid: courseUuid ?? '', organization: { uuid: organizationUuid } }) && (
              <Button className='ml-auto' hierarchy='tertiary' icon={<AddIcon />} onClick={handleAddWorkMaterial}>
                {t('common.add')}
              </Button>
            )}
          </GridRow>
          <GridRow>
            <BpCard noPadding header={{ headline: t('workmaterials.relevant') }}>
              <CourseWorkMaterialsTable
                data={[...activeMaterials].filter((m) =>
                  searchValue ? m.title.toLowerCase().includes(searchValue.toLowerCase()) : true,
                )}
                onDelete={(uuid, title) => handleDelete(uuid, title)}
                isGroupEditor={isGroupEditor}
                onClick={handleWorkMaterialClick}
                onEdit={handleWorkMaterialEditClick}
                emptyStateTitle={t('workmaterials.noRelevantWorkmaterials')}
                emptyStateSubtitle={
                  perms?.canCreateWorkmaterial({
                    uuid: courseUuid ?? '',
                    organization: { uuid: organizationUuid },
                  })
                    ? t('workmaterials.createHintCourse')
                    : t('workmaterials.createHintStudent')
                }
              />
            </BpCard>
          </GridRow>
          <GridRow>
            <BpCard noPadding header={{ headline: t('workmaterials.moreMaterial') }}>
              <CourseWorkMaterialsTable
                data={filteredIrelevantMaterials.filter((m) =>
                  searchValue ? m.title.toLowerCase().includes(searchValue.toLowerCase()) : true,
                )}
                onDelete={(uuid, title) => handleDelete(uuid, title)}
                isGroupEditor={isGroupEditor}
                onClick={handleWorkMaterialClick}
                onEdit={handleWorkMaterialEditClick}
                emptyStateTitle={t('workmaterials.noOtherWorkmaterials')}
                emptyStateSubtitle={
                  perms?.isGroupEditor({
                    uuid: courseUuid ?? '',
                    organization: { uuid: organizationUuid },
                  })
                    ? t('workmaterials.createHintCourse')
                    : t('workmaterials.createHintStudent')
                }
              />
            </BpCard>
          </GridRow>
        </Grid>
      )}
      {workmaterialModalOpen && (
        <Modal
          isOpen={workmaterialModalOpen}
          onRequestClose={() => setWorkmaterialModalOpen(false)}
          hideFooter
          hideHeader
          isFormModal
        >
          <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
            <CourseWorkMaterialsForm
              workMaterialUuid={currentWorkmaterialUuid}
              currentTeachingUnitUuid={teachingUnitUuid ?? ''}
              isModal
              onClose={async (uuid, title) => {
                await handleClose(uuid, title);
                setWorkmaterialModalOpen(false);
              }}
              context={context}
            />
          </Suspense>
        </Modal>
      )}
      <ConfirmationDialog />
    </BpSubpage>
  );
};
